import axios from "axios";

const OPENAI_API_URL = "https://api.openai.com/v1/chat/completions";

const headers = {
  "Content-Type": "application/json",
  Authorization:
    "Bearer sk-clear-path-to-cash-app-IE7N7YnVZENmsO0bC0EgT3BlbkFJnmB8rwctCHTfpV1QOStp",
};

const body = {
  model: "gpt-3.5-turbo",
  messages: [{ role: "user", content: "" }],
  max_tokens: 1000,
  n: 1,
  stop: null,
  temperature: 0.7,
};

const setSystemContent = (content) => ({
  ...body,
  messages: [{ ...body.messages[0], content }],
});

export const autoCorrect = async (key, value) => {
  let prompt;

  switch (key) {
    case "auto-correct":
      // prompt = `You are an AI assistant tasked with correcting and formatting the following text. The text may contain bullets, lists, and headings of react quill. Just return the correct version of text dont say anything else. Here is the text for you to check: ${value}`;
      prompt = `Please correct any grammatical, spelling, punctuation, or syntax errors in the provided text while maintaining the original meaning and tone. Note that the text might be written from react quill. . Here is the text for you to check: ${value}`;
      break;
    case "rephrase":
      prompt = `You are an AI assistant tasked with rephrasing the following text. The text may contain bullets, lists, and headings of react quill. Use simple, clear and concise language and avoid complex or "flowery" words. Here is the text for you to check: ${value}. If you're unable to rephrase it or don't understand it, kindly return the original text`;
      break;
    case "summarise":
      // prompt = `You are an AI assistant tasked with summarize the following text. The text may contain bullets, lists, and headings of react quill. Just return the correct version of text dont say anything else. Here is the text for you to check: ${value}`;
      prompt = `Summarize the content which given to you which is in react quill format. Here is the text for you to check: ${value}`;
      break;
    case "continue-writing":
      // prompt = `You are an AI assistant tasked to generate more content based on the current text. You can also predict text based on the current text. The text may contain bullets, lists, and headings of react quill. Just return the correct version of text dont say anything else. Here is the text for you to check: ${value}`;
      prompt = `Predict more content based on content which given to you which is in react quill format. Strictly follow these rules 1- The content which given to you should not be replaced with new content 2- The new content should be added after the content which given to you ends 3- Always return full content, mean include both given content and your generated content. Here is the text for you to: ${value}`;
      break;
    default:
      break;
  }

  const body = setSystemContent(prompt);

  const resp = await axios.post(OPENAI_API_URL, body, { headers });

  return resp.data.choices[0].message.content;
};
