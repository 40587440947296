import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const FETCH_COMP_DETAILS = "FETCH_COMP_DETAILS";
export const COMP_DETAILS_LOADING = "COMP_DETAILS_LOADING";
export const EMPTY_STATE = "EMPTY_STATE";

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

export const INIT_STATES = "INIT_STATES";
export const STOP_COMP_DETAILS_LOADING = "STOP_COMP_DETAILS_LOADING";

export const iniateStatesCompanyDetails = () => async (dispatch) => {
  dispatch({
    type: INIT_STATES,
  });
};

export const checkPeriodDuplicate = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_DETAILS_LOADING,
    });

    await axios.post(`${BASE_URL}/hiddenCash/checkPeriod`, payload);
    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });
    return true;
  } catch (error) {
    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const fetchCompanyDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_DETAILS_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/company/id`, payload);

    dispatch({
      type: FETCH_COMP_DETAILS,
      payload: response.data,
      status: "success",
    });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const updateCompanyDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_DETAILS_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/period/update`, payload);

    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const deletePeriod = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_DETAILS_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/hiddenCash/delete`, payload);

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const emptyStates = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPTY_STATE,
    });
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const addCompanyDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_DETAILS_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/hiddenCash/add`, payload);

    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: STOP_COMP_DETAILS_LOADING,
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Server error",
      },
    });
    clearSnack(dispatch);
  }
};
