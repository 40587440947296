import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { addYears, removeComma } from "../../utils/utilFunctions";
import formateDate from "../../utils/formateDate";

const OurPerformace = ({
  priorSaving,
  tvGoal,
  latestEquity,
  transferableValues,
  createdAt,
  formateWithCurrency,
  yearsUntilExit,
  selectedScenario,
  handleSaleOfBusiness,
}) => {
  useEffect(() => {}, [
    priorSaving,
    tvGoal,
    latestEquity,
    transferableValues,
    selectedScenario,
  ]);

  const handleStillNeed = () => {
    if (priorSaving && tvGoal) {
      const dumStillNeed =
        parseInt(tvGoal) -
        parseInt(priorSaving.replaceAll(",", "")) -
        (transferableValues.length
          ? transferableValues.reduce(
              (sum, item) => sum + parseInt(removeComma(item.amount)),
              0
            )
          : 0) -
        parseInt(selectedScenario?.isIncludeSales ? latestEquity : 0);

      return dumStillNeed >= 0 ? dumStillNeed : 0;
    } else return 0;
  };

  // Values for each section
  const sections = [
    {
      value: priorSaving
        ? formateWithCurrency(parseInt(priorSaving.replace(/,/g, ""), 10))
        : 0,
      label: "Prior Savings",
      color: "gray",
      date: formateDate(createdAt ? createdAt : Date.now()),
    },
    {
      value: formateWithCurrency(
        transferableValues.reduce(
          (sum, item) => sum + parseInt(removeComma(item.amount)),
          0
        )
      ),
      label: "TV Captured",
      color: "deepskyblue",
      date: formateDate(Date.now()),
    },
    {
      value: formateWithCurrency(handleStillNeed()),
      label:
        parseInt(handleStillNeed()) <= 0 ? "Goal Achieved!" : "Still Needed",
      color: parseInt(handleStillNeed()) <= 0 ? "#e9ca20" : "tomato",
      date: formateDate(
        addYears(createdAt ? createdAt : Date.now(), parseInt(yearsUntilExit))
      ),
    },
    {
      // value: formateWithCurrency(parseInt(latestEquity)),

      value: formateWithCurrency(
        selectedScenario?.isIncludeSales ? parseInt(latestEquity) : 0
      ),

      // handleSaleOfBusiness(latestEquity,transferableValues.reduce(
      //   (sum, item) => sum + parseInt(removeComma(item.amount)),
      //   0
      // ),  parseInt(priorSaving.replace(/,/g, ""), 10)),
      label: "Sale Of Business",
      color: "mediumseagreen",
      ml: 2,
    },
  ];

  return (
    <Box width="100%">
      {/* Progress Bar */}
      <Box
        display="flex"
        width="100%"
        height="100px"
        overflow="hidden"
        position="relative"
      >
        {sections.map((section, index) => (
          <Box
            key={index}
            bgcolor={section.color}
            width="50%"
            // width={`${(section.value / totalValue) * 100}%`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="white"
            ml={section.ml}
          >
            <Typography variant="body1">
              {section.value.toLocaleString()}
            </Typography>
            <Typography variant="body2">{section.label}</Typography>
          </Box>
        ))}
      </Box>

      {/* Date Labels */}
      <Box display="flex" justifyContent="space-between" mt={1}>
        {sections.map((section, index) => (
          <Typography key={index} variant="body2">
            {section.date}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default OurPerformace;
