import {
  CURRENT_RATIO,
  GROSS_MARGIN,
  NET_MARGIN,
  LOW,
  HIGH,
  AVERAGE,
  QUICK_RATIO,
  DEDT_EQUITY_RATIO,
  SALES_TO_ASSETS,
  EBITDA,
  ROA,
  ROI,
  INVENTORY_TURNOVER,
  AR_TURNOVER,
  AP_TURNOVER,
  DCOH,
  DSCR,
} from "./constants";
import currency_symbols from "./currency";

export const handleSign = (val) => {
  if (val < 0) return "-";
};
export function isValidEmail(email) {
  // Regular expression pattern for a basic email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Use the test method to check if the email matches the pattern
  return emailPattern.test(email);
}

export const getLastDigits = (string) => {
  return string.slice(string.length - 7).toUpperCase();
};

export const handlInfo = (val) => {
  let URL = "";

  switch (val) {
    case "companyDetail":
      URL = "https://cashflowmike.com/appdocs/working-with-your-user-profile/";
      break;

    case "teamManagement":
      URL = "https://cashflowmike.com/appdocs/working-with-your-user-profile/";
      break;

    case "company":
      URL =
        "https://cashflowmike.com/appdocs/getting-started-with-the-clear-path-to-cash-app/";
      break;

    case "financialData":
      URL = "https://cashflowmike.com/appdocs/financial-data-tab/";
      break;

    case "hiddenCash":
      URL =
        "https://cashflowmike.com/appdocs/using-the-hidden-cash-feature-in-the-clear-path-to-cash-app/";
      break;

    case "fmf":
      URL =
        "https://cashflowmike.com/appdocs/using-the-fast-money-formula-feature-in-the-clear-path-to-cash-app/";
      break;

    case "hrfs":
      URL =
        "https://cashflowmike.com/appdocs/using-the-home-run-financial-system-in-the-clear-path-to-cash-app/";
      break;

    case "svf":
      URL =
        "https://cashflowmike.com/appdocs/using-the-simple-valuation-formula-feature-in-the-clear-path-to-cash-app/";
      break;

    case "pdf":
      URL =
        "https://cashflowmike.com/appdocs/using-the-pdf-creator-feature-in-the-clear-path-to-cash-app/";
      break;

    case "financialDoctor":
      URL =
        "https://cashflowmike.com/appdocs/how-to-use-the-financial-doctor-feature-in-the-clear-path-to-cash-app/";
      break;

    case "sweim":
      URL =
        "https://cashflowmike.com/appdocs/using-the-start-with-the-end-in-mind-feature-in-the-clear-path-to-cash-app/";
      break;

    default:
      URL = "";
  }

  window.open(URL, "_blank");
};

export const areRequiredValuesExists = (page, periodData, helperVars) => {
  if (page === "FMF") {
    const { isService } = helperVars;

    if (
      !periodData.incomeStatement.salesinUSD ||
      !periodData.incomeStatement.costOfGoodsSoldinUSD ||
      !periodData.balanceSheet.currenAssets ||
      !periodData.balanceSheet.accountReceivableinUSD ||
      !periodData.balanceSheet.inventoryinUSD ||
      !periodData.balanceSheet.currenLiabilities ||
      !periodData.balanceSheet.accountPayableinUSD ||
      isService
        ? !periodData.incomeStatement.avgPayrollExpense ||
          !periodData.incomeStatement.daysInPayroll
        : false
    )
      return true;
    return false;
  } else if (page === "HiddenCash") {
    if (
      !periodData.ownerGoal.salesToAssets ||
      !periodData.ownerGoal.grossProfitePercentage ||
      !periodData.ownerGoal.accountsPayableTurnoverRate ||
      !periodData.ownerGoal.accountsReceivableTurnoverRate ||
      !periodData.ownerGoal.netProfitePercentage ||
      !periodData.ownerGoal.inventoryTurnoverRate ||
      !periodData.incomeStatement.salesinUSD ||
      !periodData.incomeStatement.costOfGoodsSoldinUSD ||
      !periodData.incomeStatement.grossProfiteinUSD ||
      !periodData.incomeStatement.netProfiteBeforeTaxinUSD ||
      !periodData.balanceSheet.accountReceivableinUSD ||
      !periodData.balanceSheet.inventoryinUSD ||
      !periodData.balanceSheet.totalAssetsinUSD ||
      !periodData.balanceSheet.accountPayableinUSD
    )
      return false;
    return true;
  } else if (page === "HomeRun") {
    if (
      !periodData.incomeStatement.salesinUSD ||
      !periodData.incomeStatement.grossProfiteinUSD ||
      !periodData.incomeStatement.operatingExpense ||
      !periodData.incomeStatement.interestExpense ||
      !periodData.incomeStatement.depExpense ||
      !periodData.incomeStatement.netProfiteBeforeTaxinUSD ||
      !periodData.incomeStatement.taxesPaid ||
      !periodData.incomeStatement.amortExpense ||
      !periodData.balanceSheet.grossFixedAssets ||
      !periodData.balanceSheet.longTermDebt ||
      !periodData.balanceSheet.totalLiabilities ||
      !periodData.balanceSheet.retainedEarnings ||
      !periodData.balanceSheet.totalEquity ||
      !periodData.cashFlow.financingActivities ||
      !periodData.cashFlow.investingActivities ||
      !periodData.cashFlow.operatingActivities
    )
      return false;
    return true;
  }
};

const AvgVals = {
  headline: "",
  conclusion:
    "The business ratio currently stands at an average level, indicating that the company is performing on par with industry norms.",
  suggestion:
    "To enhance competitive advantage and improve financial health, strategies should be implemented to optimize operations and increase this ratio above the industry average.",
};

export const getComparisonDetails = (
  metric,
  range,
  financialVal,
  industryAvgs,
  currency
) => {
  switch (metric) {
    case CURRENT_RATIO:
      switch (range) {
        case LOW:
          const { currentRatioInusVal, arTurnoverRate } = industryAvgs;

          let targetCurrentAssetsAmount =
            financialVal.balanceSheet.currenLiabilities * currentRatioInusVal;

          targetCurrentAssetsAmount = targetCurrentAssetsAmount
            ? `${currency_symbols[currency]}${parseFloat(
                targetCurrentAssetsAmount?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Check Your Cash Conversion Cycle!",
            conclusion:
              "Low current ratios indicate that the company may be burdened with current debt and isn't making enough money from its operations to support itself.  If a company has to sell fixed assets to pay for current liabilities, then it is losing money.  This may indicate a poor collection problem on accounts receivable.",
            suggestion: `Reduce current liabilities by increasing cash from collecting payments on invoices.  You can start by collecting  your older invoices anything over ${(
              365 / arTurnoverRate
            ).toFixed(
              0
            )} days old. Check to see if this is a cash conversion crisis or a profit squeeze (not enough net profit). Check for an expense control problem, or they might be underutilizing assets if current ratio trend is decreasing.`,
          };

        case HIGH:
          return {
            headline: "Resources Might Be Available For Growth!",
            conclusion:
              "A higher current ratio is always more favorable because it indicates that a company can more easily make its current debt payments. ",
            suggestion:
              "This means the company is keeping more safety reserve than needed and may be sitting on too much cash. If current ratio is increasing then they might be growing into capacity.  Combination of actions that do not reduce the current ration below the target. They should increase short term borrowing to grow, Find ways to deploy cash, or loosen collection terms.",
          };
          break;

        case AVERAGE:
          return AvgVals;

          break;
        default:
          break;
      }

      break;

    case QUICK_RATIO:
      let { quickRatioInusVal, arTurnoverRate } = industryAvgs;

      switch (range) {
        case LOW:
          let shortTermDebt =
            (financialVal.balanceSheet.cash +
              financialVal.balanceSheet.accountReceivableinUSD) /
            quickRatioInusVal;

          shortTermDebt = shortTermDebt
            ? `${currency_symbols[currency]}${parseFloat(
                shortTermDebt?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Low On Cash and Maybe Struggling To Pay Bills",
            conclusion:
              "Having a low quick ratio means the company may not be able to pay its current liabilities without selling long term or capital assets.  Since most companies use long term assets to generate revenue, selling these assets will hurt the company and show that current operations are making enough money to cover current liabilities.",
            suggestion: `Pay down short term debts to a total of ${shortTermDebt},   Collect invoices that are ${(
              365 / arTurnoverRate
            ).toFixed(0)} days or older, Pay your vendor invoices faster.`,
          };
        case HIGH:
          let targetCashAR =
            financialVal.balanceSheet.currenLiabilities * quickRatioInusVal;
          targetCashAR = targetCashAR
            ? `${currency_symbols[currency]}${parseFloat(
                targetCashAR?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          let borrowingAmont =
            (financialVal.balanceSheet.cash +
              financialVal.balanceSheet.accountReceivableinUSD) /
            quickRatioInusVal;

          borrowingAmont = borrowingAmont
            ? `${currency_symbols[currency]}${parseFloat(
                borrowingAmont?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Too Much Cash on Hand?",
            conclusion:
              "Higher quick ratios are more favorable because it shows that a company could pay off its current liabilities without selling any long term assets.  If the ratio increases, so does the liquidity - more assets can be quickly converted to cash if needed.  This is a good sign to creditors who want to know they will be paid back in time.",
            suggestion: `Reduce Cash + AR to a total of ${targetCashAR} buy adding  revenue generating assets in your company, Increase short term borrowing to a total of ${borrowingAmont} by acquiring revenue generating assets.`,
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case DEDT_EQUITY_RATIO:
      let { debtEquityRatioIndusVal } = industryAvgs;

      let roomForDebt =
        debtEquityRatioIndusVal * financialVal.balanceSheet.totalEquity;

      roomForDebt = roomForDebt
        ? `${currency_symbols[currency]}${parseFloat(
            roomForDebt?.toFixed(2)
          ).toLocaleString()}`
        : 0;

      switch (range) {
        case HIGH:
          return {
            headline: "The Owners Have Some Skin In The Game!",
            conclusion:
              "A lower debt to equity ratio usually implies a more financially stable business.  It also means that the investors believe that the company is performing well and are reinvesting in the business operation to ensure continued growth and success.  A company in this position can leverage debt to rapidly grow their business.",
            suggestion: `Now might be a good time to consider debt for growth.  Possible room for ${roomForDebt} in total debt.`,
          };

        case AVERAGE:
          return AvgVals;

        case LOW:
          let reduceExpenses =
            financialVal.balanceSheet.totalLiabilities /
            debtEquityRatioIndusVal;

          reduceExpenses = reduceExpenses
            ? `${currency_symbols[currency]}${parseFloat(
                reduceExpenses?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Watch Out! Debt Can Cause Problems.",
            conclusion:
              "Creditors view a higher debt to equity ratio as risky because it shows that the investors haven't funded the operations as much as creditors have.  In other words, investors don't have as much skin in the game.  It could mean that investors don't want to fund the business operation because of poor performance.  Poor performance may be the reason the company is seeking debt financing.",
            suggestion: `Reduce expenses to a total of ${reduceExpenses} to increase retained earnings (note this effects income tax), Pay down long term debt, Reduce or eliminate shareholder distributions short term. `,
          };

        default:
          break;
      }
      break;
    case GROSS_MARGIN:
      switch (range) {
        case LOW:
          return {
            headline: "Something may be off in your pricing.",
            conclusion:
              "A company with a low gross margin ratio isn't selling their goods or service as profitably as it could.  They may need to negotiate with their vendors, reevaluate their direct labor cost, or increase the price of their goods.  A low gross margin indicates that there is less money available for operational expenses.  This also may lead to a low net profit margin.",
            suggestion:
              " 1.  When was the last time you raised prices?  2.  When was the last time your negotiated with your suppliers?  3.  What all do you include in your Cost of Goods Sold? (This could just be an accounting issue.)  4.  Are you taking on too much low margin work?",
          };
        case AVERAGE:
          return AvgVals;
        case HIGH:
          return {
            headline: "This is what it feels like to stack cash!",
            conclusion:
              "Higher gross margin ratios typically means that the company is selling their inventory or service at a higher profit percentage.  They are  keeping inventory or direct labor costs down and/or marking their good up higher.  Obviously this is more favorable.",
            suggestion:
              "1.  How do your prices compare to your competitors?  2. What all do you include in your Cost of Goods Sold? (This could just be an accounting issue.)  ",
          };
        default:
          break;
      }
      break;

    case NET_MARGIN:
      switch (range) {
        case LOW:
          return {
            headline: "Time to look for ways to make more money.",
            conclusion:
              "A company with a low net margin ratio is an indicator of how the business is supporting its operations.  Companies that make enough money from their operations to support the business is usually considered more stable.  However, a company that requires both operating and non-operating income to cover its expenses show others that the business' operating activities are not sustainable.  This company should increase their gross profit, and/or reduce operating expenses.",
            suggestion:
              "1.  When was the last time you evaluated your occupancy costs?  2.  When was the last time your negotiated with your administrative services providers?  3.  Are your wages in line or above the market rate for your administrative positions  4.  Are their categories you think you are overspending in?",
          };
        case HIGH:
          return {
            headline: "Nice! You are the envy of every business owner.",
            conclusion:
              "A higher net margin is more favorable because it shows that the company is making enough money from its business operations to pay for its variable and fixed costs.  This is the most envious of all business positions, and allows the business owner to build a 'war chest' to fund future operations that defend against threats or take advantage of opportunities in the marketplace. ",
            suggestion:
              " 1.  How do your wage rates compare to the market?  Low wages can contribute to turnover and training costs.  2. Are you spending enough in marketing and advertising?  3.  How do you invest the net profit back into the company?  4.  How comfortable are you with your tax strategy?  ",
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case SALES_TO_ASSETS:
      switch (range) {
        case LOW:
          const { salesToAssetsIndusVal } = industryAvgs;

          let increasSales =
            financialVal.balanceSheet.totalAssetsinUSD * salesToAssetsIndusVal;

          increasSales = increasSales
            ? `${currency_symbols[currency]}${parseFloat(
                increasSales?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "You have plenty of room to grow.",
            conclusion:
              "Generally a low or decreasing Sales to Assets ratio is a bad sign.  This says that the management team isn't fully utilizing the resources they have to grow the company.  On a positive note, there are underutilized assets and an opportunity to increase sales without another investment in equipment.",
            suggestion: `1. Increase sales to a total of ${increasSales}.  2. Sell or dispose unused or obsolete equipment.`,
          };

        case HIGH:
          return {
            headline: "Keep an eye out for capacity issues to develop.",
            conclusion:
              "A higher Sales to Assets ratio indicates that the company is making good use of it's resources to generate sales.  This can also be a sign that the company is approaching a capacity issue where more investment in equipment might be required soon.",
            suggestion:
              "1. Begin planning for replacing or repairing current equipment.  2. Consider growth by investing in more equipment. 3. Look into a customer migration strategy to remove customers with low/no contribution margin.",
          };

        case AVERAGE:
          return AvgVals;
        default:
          break;
      }

      break;

    case EBITDA:
      switch (range) {
        case LOW:
          const {
            salesToAssetsIndusVal,
            grossProfitIndusVal,
            operatingExpenseIndusVal,
          } = industryAvgs;

          let sales =
            financialVal.balanceSheet.totalAssetsinUSD * salesToAssetsIndusVal;
          let grossProfit =
            financialVal.incomeStatement.salesinUSD * grossProfitIndusVal;
          let operatingExpense =
            financialVal.incomeStatement.salesinUSD * operatingExpenseIndusVal;

          sales = sales
            ? `${currency_symbols[currency]}${parseFloat(
                sales?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          grossProfit = grossProfit
            ? `${currency_symbols[currency]}${parseFloat(
                grossProfit?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          operatingExpense = operatingExpense
            ? `${currency_symbols[currency]}${parseFloat(
                operatingExpense?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "You might be leaving money on the table",
            conclusion:
              "A low EBITDA means that the company has two problems.  One is that they have a problem with profitability, the other is that they also may be experiencing cash flow problems.",
            suggestion: `1. Increase Net profit by Sales (${sales}), Gross Profit (${grossProfit}), Operating Expense (${operatingExpense}). 2. Evaluate depreciation strategy to take advantage of a more beneficial depreciation expense.`,
          };
        case HIGH:
          return {
            headline:
              "Make sure Interest and Taxes are in line with your expectations",
            conclusion:
              "You might think that a high EBITDA is always a good thing.  Normally it is, but it may also mean that the company have a heavy debt load creating a high interest charge.",
            suggestion:
              "1. Evaluate bank interest charges to see if there is cost savings.  2. Assess depreciation strategy to ensure that future years are not under represented.",
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case ROA:
      switch (range) {
        case LOW:
          return {
            headline: "Put your equipment to work for you",
            conclusion:
              "A low return on assets ratios means that the equipment you have on the books isn't producing enough income.  This could be because of low sale, high operating expenses, high inventory  or too much equipment.",
            suggestion:
              "1. Increase sales .  2. Reduce expenses. 3. Reduce accounts receivable. 4. Reduce inventory. 5. Expand the business. 6. Dispose of unnecessary equipment.",
          };
        case HIGH:
          return {
            headline:
              "You are in control and your equipment is printing money.",
            conclusion:
              "A high sales to assets ratio indicates the company knows how to use its assets to make money.  There is an opportunity to see if now is the right time to add equipment and grow.",
            suggestion:
              "1. Is now the right time to add capacity and grow using cash to by additional assets?",
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case ROI:
      switch (range) {
        case LOW:
          return {
            headline: "Other investments might be a better use of your money.",
            conclusion:
              "A low return on investment indicates that there is a problem in the business where it is not generating enough profit, or the owner's have too much equity in the company.",
            suggestion:
              "1. Increase sales. 2. Reduce expenses. 3. Buy back equity from investors if possible. ",
          };
        case HIGH:
          return {
            headline: "You have your own little gold mine here.",
            conclusion:
              "This is a great sign that you are putting your money to work.  Be sure you have not taken too much out of the company, reducing equity and inflating your returns.",
            suggestion:
              "1. Use capital to make distributions to shareholders to reduce your equity. ",
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case INVENTORY_TURNOVER:
      switch (range) {
        case HIGH:
          return {
            headline: "Inventory.  Is There Enough?",
            conclusion:
              "Lower inventory levels are mostly more favorable than higher levels.  One downfall may be the impact in future sales, as customers become frustrated with not having items available for immediate purchase.  A lower level may also indicate that the company has learned to move items more quickly to increase cash flow.  Be mindful of poor selection and back order issues that negatively impact the business operation. ",
            suggestion:
              "1.  Is low inventory a concern?  2.  When was the last time you compared your levels to others?  3.  Do you lose sales based on the lack of product availability?",
          };

        case LOW:
          return {
            headline: "Company Loose With Inventory Controls",
            conclusion:
              "Unless company is stocking up ahead of anticipated demand, the increase in inventory could indicate a slackening demand.  Inventory costs money to keep, store and maintain.  High levels may encourage theft or obsolescence.  Minimize inventory costs to increase cash flows.  The longer inventory sits on the shelf, it can cost the company extra money.",
            suggestion:
              " 1.  When was the last time you completed a physical inventory?  2.  Are you taking advantage of volume discounts?  3.  Is any of your inventory obsolete?  4.  Would ordering more often work for your business?",
          };

        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case AR_TURNOVER:
      switch (range) {
        case HIGH:
          return {
            headline: "Cash comes into this company quickly.",
            conclusion:
              "A lower AR ratio is more favorable because it means that a company can collect cash earlier from customers and use this cash for operations.  It also indicates a low risk of having to write off receivables as bad debt.  Too low may indicate a very aggressive collection practice that might negatively impact sales.",
            suggestion:
              "1.  Are you currently offering a discount for paying early?   2.  Are you entering your invoices properly?  3.  Do you place heavy emphasis on collections?  4.  Do you think having more strict terms than your competitors hurt your sales growth?",
          };

        case LOW:
          return {
            headline:
              "You need to pay attention to how customers pay your invoices.",
            conclusion:
              "Higher receivables ratios indicate poor collection procedures and customers who are unable or unwilling to pay for their purchases.  Companies with high AR Days are unable to convert sales into cash as quickly.",
            suggestion:
              "1.  Are you collecting a late fee?  2.  Are you providing invoices at the time of service or prior to delivering?  3.  Are your recording payments from your customers on the day you receive the check?",
          };

        case AVERAGE:
          return AvgVals;

        default:
          break;
      }
      break;

    case AP_TURNOVER:
      switch (range) {
        case LOW:
          return {
            headline: "Get Paid Quick.  Do Business Here",
            conclusion:
              "A low AP ratio tells suppliers that the company pays its bills frequently and regularly.  It implies that vendors will get paid back quickly.  A word of caution is that paying too quickly might negatively impact cash flow.  Consider managing this number near the industry average.",
            suggestion:
              "1.  Are you getting any discounts for paying early?  2.  Would other vendors offer you better terms?   3.  Are you paying based upon due dates or just paying on some other schedule, like once per week?",
          };
        case HIGH:
          return {
            headline: "Does This Company Have The Money To Pay Its Bills?",
            conclusion:
              "Higher AP ratios can indicate a possible cash flow problem in the company and may lead to unfavorable credit terms in future negotiations with vendors.  However, 'stretching' AP days is a tactic employed to smooth seasonality or cash flow issues on a short term basis.",
            suggestion:
              "1.  Are you paying late fees or missing out on any discounts?  2.  Are you worried about going to COD?  3.  Are you delaying paying your bills due to slow customer payments?    ",
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case DSCR:
      switch (range) {
        case LOW:
          let requiredProfit = financialVal.balanceSheet.debtPayments * 1.25;
          // 1.25 is target DSCR

          requiredProfit = requiredProfit
            ? `${currency_symbols[currency]}${parseFloat(
                requiredProfit?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Warning: Your DSCR is Too Low!",
            conclusion:
              "A low DSCR means your business isn’t generating enough profit to cover debt payments, putting you at risk of default. This could signal over-leveraging, cash flow issues, or poor profitability. If not addressed, it may force you to rely on reserves or additional financing.",
            suggestion: `To improve your DSCR, focus on increasing profits or reducing debt. You will need approximately ${requiredProfit} in profit to reduce the risk of default in your company`,
          };
        case HIGH:
          let excessiveProfit =
            financialVal.incomeStatement.netProfiteBeforeTaxinUSD -
            financialVal.balanceSheet.debtPayments * 1.25;
          // 1.25 is target DSCR

          excessiveProfit = excessiveProfit
            ? `${currency_symbols[currency]}${parseFloat(
                excessiveProfit?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "You’re doing well!  Is Expansion Next",
            conclusion:
              "A high DSCR shows you're easily covering debt, but it might mean you're not using available capital to its full potential. You could be missing growth opportunities by holding onto excess profit instead of reinvesting it.",
            suggestion: `Leverage your strong DSCR by reinvesting in growth or expansion. You might have an additional ${excessiveProfit} available to grow, as long as your minimum cash on hand amount is covered`,
          };
        case AVERAGE:
          return AvgVals;
        default:
          break;
      }
      break;

    case DCOH:
      switch (range) {
        case LOW:
          let reduceOperExpnse = (financialVal.balanceSheet.cash / 45) * 365;

          let val1 = (financialVal.incomeStatement.operatingExpense / 365) * 45;

          let val2 = (financialVal.incomeStatement.operatingExpense / 12) * 3;

          let val3 = (financialVal.incomeStatement.operatingExpense / 12) * 6;

          val1 = val1
            ? `${currency_symbols[currency]}${parseFloat(
                val1?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          val2 = val2
            ? `${currency_symbols[currency]}${parseFloat(
                val2?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          val3 = val3
            ? `${currency_symbols[currency]}${parseFloat(
                val3?.toFixed(2)
              ).toLocaleString()}`
            : 0;

          return {
            headline: "Where is your cash?",
            conclusion:
              "Usually when a company has low days cash on hand, they are also have other cash flow problems like not being able to pay their bills.  Another name for days cash on hand is days to bankruptcy",
            suggestion: `1. Build an initial cash reserve of ${val1}.  Then strive to maintain a cash balance in the company between ${val2} and ${val3} in the future.. 2. Avoid one time cash purchases in the short term.  3. Reduce or cut shareholder distributions. 4. Increase sales.  5. Have shareholders infuse capital into the company.`,
          };

          break;
        case HIGH:
          return {
            headline:
              "Now we have options and can be prepared for the unexpected.",
            conclusion:
              "At a minimum you should have 45 days cash on hand, but an optimal amount is between 3-6 months worth of operating expenses.  This way you can weather unexpected events like COVID or natural disasters.  Any more than 6 months, you should be building a reserve for a big purchase.",
            suggestion:
              "1. Look for growth opportunities by adding equipment, buying a competitor.  2. Pay dividends or take shareholder distributions.",
          };
          break;
        case AVERAGE:
          return AvgVals;
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }
};

export const combineImages = async (
  canvas1,
  canvas64Image1,
  canvas2,
  canvas64Image2
) => {
  return new Promise((resolve, reject) => {
    const combinedCanvas = document.createElement("canvas");
    const ctx = combinedCanvas.getContext("2d");
    combinedCanvas.width = Math.max(canvas1.width, canvas2.width);
    combinedCanvas.height = canvas1.height + canvas2.height;

    // Draw first image
    const image1 = new Image();
    image1.src = canvas64Image1;
    image1.onload = function () {
      ctx.drawImage(image1, 0, 0);

      // Draw second image below the first one
      const image2 = new Image();
      image2.src = canvas64Image2;
      image2.onload = function () {
        ctx.drawImage(image2, 0, canvas1.height);

        // Convert combined canvas to base64 image
        const combined64Image = combinedCanvas.toDataURL("image/png");
        resolve(combined64Image);
      };
    };
  });
};

export function calculateValuesForCurrentRatio(number) {
  // Calculate 5% decrease

  //logic here is:
  // creating 12 values for the scale
  // 3 that are lower of 2% of the actual value
  // 3 that are lower of 10% // / //
  // 3 that are higher 2% // / //
  // 3 that are higher 33% // / //

  const decrease2Perc = number * 0.02;
  const decrease10Perc = number * 0.1;
  const increase33Perc = number * 0.33;
  const increase2Perc = number * 0.02;

  const higher1 = number + increase33Perc;
  const higher2 = higher1 + increase33Perc;
  const higher3 = higher2 + increase33Perc;

  const higher4 = number + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower1 = number - decrease2Perc;
  const lower2 = lower1 - decrease2Perc;
  const lower3 = lower2 - decrease2Perc;

  const lower4 = number - decrease10Perc;
  const lower5 = lower4 - decrease10Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
  ];
}

export function calculateValuesForQuickRatio(number) {
  return calculateValuesForCurrentRatio(number);
}

export function calculateValuesForDebtEquityRatio(number) {
  const decrease2Perc = number * 0.02;
  const decrease25Perc = number * 0.25;
  const increase25Perc = number * 0.25;
  const increase2Perc = number * 0.02;

  const higher1 = number + increase2Perc;
  const lower1 = number - decrease2Perc;

  const higher2 = number + increase25Perc;
  const higher3 = higher2 + increase2Perc;
  const higher4 = higher3 + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower2 = number - decrease25Perc;
  const lower3 = lower2 - decrease2Perc;
  const lower4 = lower3 - decrease2Perc;
  const lower5 = lower4 - decrease2Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
  ];
}

export function calculateValuesForInventoryTurnover(number) {
  const decrease2Perc = parseInt((number * 0.02).toFixed(0));
  const decrease15Perc = parseInt((number * 0.15).toFixed(0));
  const decrease16Perc = parseInt((number * 0.16).toFixed(0));
  const decrease17Perc = parseInt((number * 0.17).toFixed(0));
  const decrease18Perc = parseInt((number * 0.18).toFixed(0));
  const increase15Perc = parseInt((number * 0.15).toFixed(0));
  const increase16Perc = parseInt((number * 0.16).toFixed(0));
  const increase17Perc = parseInt((number * 0.17).toFixed(0));
  const increase18Perc = parseInt((number * 0.18).toFixed(0));
  const increase19Perc = parseInt((number * 0.19).toFixed(0));
  const increase2Perc = parseInt((number * 0.02).toFixed(0));

  const higher1 = number + increase2Perc;

  const lower1 = number - decrease2Perc;

  const higher2 = number + increase15Perc;
  const higher3 = higher2 + increase16Perc;
  const higher4 = higher3 + increase17Perc;
  const higher5 = higher4 + increase18Perc;
  const higher6 = higher5 + increase19Perc;

  const lower2 = number - decrease15Perc;
  const lower3 = lower2 - decrease16Perc;
  const lower4 = lower3 - decrease17Perc;
  const lower5 = lower4 - decrease18Perc;

  return [
    0,
    lower5 ? lower5 : 0,
    lower4 ? lower4 : 0,
    lower3 ? lower3 : 0,
    lower2 ? lower2 : 0,
    lower1 ? lower1 : 0,
    number ? number : 0,
    higher1 ? higher1 : 0,
    higher2 ? higher2 : 0,
    higher3 ? higher3 : 0,
    higher4 ? higher4 : 0,
    higher5 ? higher5 : 0,
    higher6 ? higher6 : 0,
  ];
}

export function calculateValuesForArTurnover(number) {
  return calculateValuesForInventoryTurnover(number);
}

export function calculateValuesForGrossMargin(number) {
  const decrease2Perc = number * 0.02;
  const decrease5Perc = number * 0.5;
  const increase10Perc = number * 0.1;
  const increase2Perc = number * 0.02;
  const higher1 = number + increase2Perc;
  const lower1 = number - decrease2Perc;

  const higher2 = number + increase10Perc;
  const higher3 = higher2 + increase2Perc;
  const higher4 = higher3 + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower2 = number - decrease5Perc;
  const lower3 = lower2 - decrease2Perc;
  const lower4 = lower3 - decrease2Perc;
  const lower5 = lower4 - decrease2Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
  ];
}

export function calculateValuesForNetMargin(number) {
  const decrease2Perc = number * 0.02;
  const decrease10Perc = number * 0.1;
  const increase10Perc = number * 0.1;
  const increase2Perc = number * 0.02;
  const higher1 = number + increase2Perc;
  const lower1 = number - decrease2Perc;

  const higher2 = number + increase10Perc;
  const higher3 = higher2 + increase2Perc;
  const higher4 = higher3 + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower2 = number - decrease10Perc;
  const lower3 = lower2 - decrease2Perc;
  const lower4 = lower3 - decrease2Perc;
  const lower5 = lower4 - decrease2Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
  ];
}

export function calculateValuesForSalesToAssets(number) {
  const decrease2Perc = number * 0.02;
  const decrease10Perc = number * 0.1;
  const increase25Perc = number * 0.25;
  const increase2Perc = number * 0.02;
  const higher1 = number + increase2Perc;
  const lower1 = number - decrease2Perc;

  const higher2 = number + increase25Perc;
  const higher3 = higher2 + increase2Perc;
  const higher4 = higher3 + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower2 = number - decrease10Perc;
  const lower3 = lower2 - decrease2Perc;
  const lower4 = lower3 - decrease2Perc;
  const lower5 = lower4 - decrease2Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
  ];
}

export function calculateValuesForEbitda(number) {
  const decrease2Perc = number * 0.02;
  const decrease10Perc = number * 0.1;
  const increase25Perc = number * 0.25;
  const increase2Perc = number * 0.02;
  const higher1 = number + increase2Perc;
  const lower1 = number - decrease2Perc;

  const higher2 = number + increase25Perc;
  const higher3 = higher2 + increase2Perc;
  const higher4 = higher3 + increase2Perc;
  const higher5 = higher4 + increase2Perc;
  const higher6 = higher5 + increase2Perc;

  const lower2 = number - decrease10Perc;
  const lower3 = lower2 - decrease2Perc;
  const lower4 = lower3 - decrease2Perc;
  const lower5 = lower4 - decrease2Perc;

  return [
    0.0,
    parseFloat(lower5.toFixed(2)),
    parseFloat(lower4.toFixed(2)),
    parseFloat(lower3.toFixed(2)),
    parseFloat(lower2.toFixed(2)),
    parseFloat(lower1.toFixed(2)),
    number,
    parseFloat(higher1.toFixed(2)),
    parseFloat(higher2.toFixed(2)),
    parseFloat(higher3.toFixed(2)),
    parseFloat(higher4.toFixed(2)),
    parseFloat(higher5.toFixed(2)),
    parseFloat(higher6.toFixed(2)),
  ];
}

export function calculateValuesForRoa(number) {
  return calculateValuesForEbitda(number);
}

export function calculateValuesForRoi(number) {
  return calculateValuesForEbitda(number);
}

export function calculateValuesForApTurnover(number) {
  return calculateValuesForInventoryTurnover(number);
}

export const clearSnack = (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: "CLEAR",
    });
  }, 10000);
};

export const clientCheck = (user, dispatch) => {
  if (user.isClient) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: "This feature is reserved for subscribers",
      },
    });
    clearSnack(dispatch);
    return false;
  }
  return true;
};

export const imediateClear = (dispatch) => {
  dispatch({
    type: "CLEAR",
  });
};

export const removeComma = (val) =>
  typeof val === "string" ? parseInt(val.replaceAll(",", "")) : val;

export const roundUpToNearestThousand = (value) => {
  return Math.round(value / 1000) * 1000;
};

export const getGreaterDate = (date1, date2) => {
  // Convert the date strings to Date objects
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  // Compare the two Date objects
  if (dateObj1 > dateObj2) {
    return date1;
  } else {
    return date2;
  }
};

export const addYears = (date, years) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};
