import React, { useState, useEffect } from "react";
import { Layout } from "../../layout";
import "./style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";
import { CardWrapper } from "../../components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addClient,
  cancelSubscription,
  deleteClient,
  fetchClients,
  updateProfile,
  updateProfileName,
} from "../../redux/actions/user";
import {
  fetchUserById,
  fetchTeamMembers,
  deleteMember,
  addTeamMember,
} from "../../redux/actions/user";
import SnackBar from "../../components/snackBar";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { isValidEmail, clientCheck, handlInfo, clearSnack } from "../../utils/utilFunctions";

const TeamManagment = ({user}) => {
  const dispatch = useDispatch();

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const [memberEmail, setMemberEmail] = useState();
  const [members, setMembers] = useState([]);

  const [clientEmail, setClientEmail] = useState();
  const [clients, setClients] = useState();

  const [deleteCientloading, setDeleteClientLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteCientIndex, setDeleteClientIndex] = useState();

  const fetchTeam = async () => {
    const membersTeam = await dispatch(fetchTeamMembers());
    setMembers(membersTeam);
  };

  const getClients = async () => {
    const allClients = await dispatch(fetchClients());
    setClients(allClients);
  };

  useEffect(() => {
    fetchTeam();
    getClients();
  }, []);

  const handleDeleteMember = async (member, i) => {
    setDeleteLoading(true);
    setDeleteIndex(i);

    const data = {
      memberEmail: member.memberEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(deleteMember(data));
    fetchTeam();
    setDeleteLoading(true);
  };

  const handleDeleteClient = async (clnt, i) => {
    setDeleteClientLoading(true);
    setDeleteClientIndex(i);

    const data = {
      clientEmail: clnt.clientEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(deleteClient(data));
    setClientEmail("");
    getClients();
    setDeleteClientLoading(false);
  };

  const handleAddMember = async () => {
    if (!clientCheck(user, dispatch)) return;
    setAddLoading(true);
    if (!memberEmail) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter Member Email",
        },
      });
      clearSnack(dispatch);
      setAddLoading(false);
      return;
    }
    if (!isValidEmail(memberEmail)) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter a valid Email",
        },
      });
      clearSnack(dispatch);
      setAddLoading(false);
      return;
    }

    const data = {
      memberEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(addTeamMember(data));
    fetchTeam();
    setMemberEmail("");
    setAddLoading(false);
  };

  const handleAddClient = async () => {

    if (!clientCheck(user, dispatch)) return;

    setAddLoading(true);

    if (!clientEmail) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter Client Email",
        },
      });
      clearSnack(dispatch);
      setAddLoading(false);
      return;
    }

    if (!isValidEmail(clientEmail)) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter a valid Email",
        },
      });
      clearSnack(dispatch);
      setAddLoading(false);
      return;
    }

    const data = {
      clientEmail,
      ownerId: localStorage.getItem("id"),
    };

    await dispatch(addClient(data));
    getClients();
    setAddLoading(false);
  };

  return (
    <CardWrapper>
      <Typography className="hidden_cash_subTitle">
        Team Management{" "}
        <InfoOutlinedIcon
          onClick={() => handlInfo("teamManagement")}
          className="infor_icon"
        />
      </Typography>
      <Box style={{ height: 30, width: "100%" }} />
      <Grid container item xs={12}>
        <Box style={{ height: 25, width: "100%" }} />
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography className="hidden_cash_subTitle">Members</Typography>
          <Button
            onClick={handleAddMember}
            disabled={addLoading}
            className={user?.isClient?"disabled_add_member_btn":"add_member_btn"}
          >
            <AddCircleOutlineIcon style={{ fontSize: 18, marginRight: 10 }} />{" "}
            Add Member
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "grid", justifyContent: "stretch" }}
        >
          {/* <Typography style={{ fontSize: 16, marginBottom: 12 }}>
            Email
          </Typography> */}
          <input
            onChange={(e) => setMemberEmail(e.target.value)}
            className="companydetail_inputs"
            placeholder="Enter member email to add"
          />
        </Grid>
        <Box style={{ height: 30, width: "100%" }} />
        <CardWrapper className="add_team_box" border="1px solid #E7E7E7">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F2F2F5",
              padding: "15px 20px",
              borderRadius: "100px",
            }}
          >
            <Typography>Email</Typography>
            <Typography>Action</Typography>
          </Grid>
          {!members || !members.length ? (
            <>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "55px 0px",
                }}
              >
                No data found
              </Typography>
              <Box style={{ height: 50, width: "100%" }} />
            </>
          ) : (
            <>
              <Grid
                container
                xs={12}
                style={{
                  maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {members.map((member, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // backgroundColor: "#F2F2F5",
                      padding: "0 20px",
                      borderRadius: "100px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        paddingTop: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {member.isSignedUp ? (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Member signed up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <CheckCircleIcon
                            style={{
                              color: "#00a0ff",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Waiting for member to sign up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <QueryBuilderIcon
                            style={{
                              color: "#ffa000",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      )}
                      {member.memberEmail}
                    </Typography>{" "}
                    <Button style={{ height: "75px" }}>
                      {deleteLoading && deleteIndex == i ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      ) : (
                        <DeleteOutlineIcon
                          onClick={() => handleDeleteMember(member, i)}
                          style={{ color: "#d81818" }}
                        />
                      )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </CardWrapper>

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "25px",
            marginBottom: "10px",
          }}
        >
          <Typography className="hidden_cash_subTitle">Clients</Typography>
          <Button
            onClick={handleAddClient}
            disabled={addLoading}
            className={user?.isClient?"disabled_add_member_btn":"add_member_btn"}
          >
            <AddCircleOutlineIcon style={{ fontSize: 18, marginRight: 10 }} />{" "}
            Add Client
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "grid", justifyContent: "stretch" }}
        >
          {/* <Typography style={{ fontSize: 16, marginBottom: 12 }}>
            Email
          </Typography> */}
          <input
            onChange={(e) => setClientEmail(e.target.value)}
            className="companydetail_inputs"
            placeholder="Enter client email to add"
          />
        </Grid>
        <Box style={{ height: 30, width: "100%" }} />
        <CardWrapper className="add_team_box" border="1px solid #E7E7E7">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F2F2F5",
              padding: "15px 20px",
              borderRadius: "100px",
            }}
          >
            <Typography>Email</Typography>
            <Typography>Action</Typography>
          </Grid>
          {!clients || !clients.length ? (
            <>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "55px 0px",
                }}
              >
                No data found
              </Typography>
              <Box style={{ height: 50, width: "100%" }} />
            </>
          ) : (
            <>
              <Grid
                container
                xs={12}
                style={{
                  maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {clients.map((clnt, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // backgroundColor: "#F2F2F5",
                      padding: "0 20px",
                      borderRadius: "100px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        paddingTop: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {clnt.isSignedUp ? (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Client signed up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <CheckCircleIcon
                            style={{
                              color: "#00a0ff",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Waiting for client to sign up
                            </span>
                          }
                          arrow
                          placement="top"
                        >
                          <QueryBuilderIcon
                            style={{
                              color: "#ffa000",
                              fontSize: "20px",
                              paddingRight: "5px",
                            }}
                          />
                        </Tooltip>
                      )}

                      {clnt.clientEmail}
                    </Typography>
                    <Button style={{ height: "75px" }}>
                      {deleteCientloading && deleteCientIndex == i ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      ) : (
                        <DeleteOutlineIcon
                          onClick={() => handleDeleteClient(clnt, i)}
                          style={{ color: "#d81818" }}
                        />
                      )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </CardWrapper>

        {/*  */}
      </Grid>
      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </CardWrapper>
  );
};

export default TeamManagment;
