import axios from "axios";
import { BASE_URL } from "../../utils/config";
import formateDate from "../../utils/formateDate";
import { clearSnack, getGreaterDate } from "../../utils/utilFunctions";

export const INDUS_AVG_NOTES = "INDUS_AVG_NOTES";
export const COMP_RATIO_NOTES = "COMP_RATIO_NOTES";
export const OWN_GRAPH_NOTES = "OWN_GRAPH_NOTES";
export const INV_GRAPH_NOTES = "INV_GRAPH_NOTES";
export const SERVICE_GRAPH_NOTES = "SERVICE_GRAPH_NOTES";
export const MIS_MATCH_NOTES = "MIS_MATCH_NOTES";
export const CASH_FLOW_NOTES = "CASH_FLOW_NOTES";
export const EXPENSE_CNTRL_NOTES = "EXPENSE_CNTRL_NOTES";
export const EBIDTA_NOTES = "EBIDTA_NOTES";
export const DEBY_EQ_NOTES = "DEBY_EQ_NOTES";
export const SIMPLE_VAL_NOTES = "SIMPLE_VAL_NOTES";
export const START_WITH_END_NOTES = "START_WITH_END_NOTES";
export const TRENDS_NOTES = "TRENDS_NOTES";
export const CURRENT_RATIO_NOTES = "CURRENT_RATIO_NOTES";
export const QUICK_RATIO_NOTES = "QUICK_RATIO_NOTES";
export const DEBT_EQUITY_NOTES = "DEBT_EQUITY_NOTES";
export const GROSS_MARGIN_NOTES = "GROSS_MARGIN_NOTES";
export const NET_MARGIN_NOTES = "NET_MARGIN_NOTES";
export const SALE_ASSETS_NOTES = "SALE_ASSETS_NOTES";
export const EBIDTA_FD_NOTES = "EBIDTA_FD_NOTES";
export const ROA_NOTES = "ROA_NOTES";
export const ROI_NOTES = "ROI_NOTES";
export const INV_TURNOVER_NOTES = "INV_TURNOVER_NOTES";
export const AR_TURNOVER_NOTES = "AR_TURNOVER_NOTES";
export const AP_TURNOVER_NOTES = "AP_TURNOVER_NOTES";
export const DCOH_NOTES = "DCOH_NOTES";
export const DSCR_NOTES = "DSCR_NOTES";

export const NOTE_LOADING = "NOTE_LOADING";
export const NOTE_LOADING_STOP = "NOTE_LOADING_STOP";

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

export const INIT_STATES = "INIT_STATES";

export const iniateStatesNotes = () => async (dispatch) => {
  dispatch({
    type: INIT_STATES,
  });
};

const getReducerCase = (key) => {
  switch (key) {
    case "industryAvgGraphNotes":
      return "INDUS_AVG_NOTES";
    case "ownGoalGraphNotes":
      return "OWN_GRAPH_NOTES";
    case "comparativeRatioNotes":
      return "COMP_RATIO_NOTES";
    case "inventCompFMFNotes":
      return "INV_GRAPH_NOTES";
    case "serviceCompFMFNotes":
      return "SERVICE_GRAPH_NOTES";
    case "trendsNotes":
      return "TRENDS_NOTES";
    case "expenseControlNotes":
      return "EXPENSE_CNTRL_NOTES";
    case "debtEqRatioNotes":
      return "DEBY_EQ_NOTES";
    case "ebidtaNotes":
      return "EBIDTA_NOTES";
    case "misMatchNotes":
      return "MIS_MATCH_NOTES";
    case "cashFlowActvtyNotes":
      return "CASH_FLOW_NOTES";
    case "simpleValNotes":
      return "SIMPLE_VAL_NOTES";
    case "currentRatioChart":
      return "CURRENT_RATIO_NOTES";
    case "quickRatioChart":
      return "QUICK_RATIO_NOTES";
    case "debtEqRatioChart":
      return "DEBT_EQUITY_NOTES";
    case "grossMarginChart":
      return "GROSS_MARGIN_NOTES";
    case "netMarginChart":
      return "NET_MARGIN_NOTES";

    case "startWithEndNotes":
      return "START_WITH_END_NOTES";

    case "saleAssetsChart":
      return "SALE_ASSETS_NOTES";

    case "ebidtaFDChart":
      return "EBIDTA_FD_NOTES";

    case "roaChart":
      return "ROA_NOTES";

    case "roiChart":
      return "ROI_NOTES";

    case "invTurnoverChart":
      return "INV_TURNOVER_NOTES";

    case "arTurnoverChart":
      return "AR_TURNOVER_NOTES";

    case "apTurnoverChart":
      return "AP_TURNOVER_NOTES";
    case "dcohChart":
      return "DCOH_NOTES";
    case "dscrChart":
      return "DSCR_NOTES";
    default:
      return "";
  }
};

export const addNote = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: NOTE_LOADING,
    });

    const res = await axios.post(`${BASE_URL}/notes/add`, payload);

    dispatch({
      type: getReducerCase(payload.key),
      payload: {
        val: res.data.data.value,
        date: getGreaterDate(res.data.data.updatedAt, res.data.data.updated_at),
      },
    });

    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: res.data.message,
      },
    });
    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const fetchHiddenCashNotes = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

    // to empty hidden cash notes from PDF
    ["ownGoalGraphNotes", "industryAvgGraphNotes", "comparativeRatioNotes"].map(
      (key) => {
        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key,
            value: "",
          },
        });
      }
    );

    // to empty hidden cash notes
    ["INDUS_AVG_NOTES", "COMP_RATIO_NOTES", "OWN_GRAPH_NOTES"].map((key) => {
      dispatch({
        type: key,
        payload: {
          val: "",
          date: "",
        },
      });
    });

    if (res.data.data.length) {
      res.data.data.forEach((note) => {
        if (note.key === "industryAvgGraphNotes") {
          dispatch({
            type: INDUS_AVG_NOTES,
            payload: {
              val: note.value,
              date: note.updatedAt || note.updated_at,
            },
          });

          dispatch({
            type: "SAVE_NOTES_PDF",
            payload: {
              key: "industryAvg",
              value: note.value,
            },
          });

          dispatch({
            type: "UPDATE_NOTES_DATE_PDF",
            payload: {
              key: "industryAvg",
              value: formateDate(note.updated_at || note.updatedAt),
            },
          });
        }

        if (note.key === "comparativeRatioNotes") {
          dispatch({
            type: COMP_RATIO_NOTES,
            payload: {
              val: note.value,
              date: note.updatedAt || note.updated_at,
            },
          });

          dispatch({
            type: "SAVE_NOTES_PDF",
            payload: {
              key: "comprtvRatio",
              value: note.value,
            },
          });

          dispatch({
            type: "UPDATE_NOTES_DATE_PDF",
            payload: {
              key: "comprtvRatio",
              value: formateDate(note.updated_at || note.updatedAt),
            },
          });
        }

        if (note.key === "ownGoalGraphNotes") {
          dispatch({
            type: OWN_GRAPH_NOTES,
            payload: {
              val: note.value,
              date: note.updated_at || note.updatedAt,
            },
          });

          dispatch({
            type: "SAVE_NOTES_PDF",
            payload: {
              key: "ownGoal",
              value: note.value,
            },
          });

          dispatch({
            type: "UPDATE_NOTES_DATE_PDF",
            payload: {
              key: "ownGoal",
              value: formateDate(note.updated_at || note.updatedAt),
            },
          });
        }
      });
    } else {
      // to empty hidden cash notes from PDF
      ["ownGoal", "industryAvg", "comprtvRatio"].map((key) => {
        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key,
            value: "",
          },
        });
      });

      // to empty hidden cash notes
      ["INDUS_AVG_NOTES", "COMP_RATIO_NOTES", "OWN_GRAPH_NOTES"].map((key) => {
        dispatch({
          type: key,
          payload: {
            val: "",
            date: "",
          },
        });
      });
    }
  } catch (error) {
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const fetchFMFNotes = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

  dispatch({
    type: INV_GRAPH_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });
  dispatch({
    type: SERVICE_GRAPH_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  if (res.data.data.length) {
    res.data.data.forEach((note) => {
      if (note.key === "inventCompFMFNotes") {
        dispatch({
          type: INV_GRAPH_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "fmfInvenWhatIf",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "fmfInvenWhatIf",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "serviceCompFMFNotes") {
        dispatch({
          type: SERVICE_GRAPH_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "fmfServiceWhatIf",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "fmfServiceWhatIf",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }
    });
  } else {
    dispatch({
      type: INV_GRAPH_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: SERVICE_GRAPH_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
  }
};

export const fetchHRFSNotes = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

  dispatch({
    type: CASH_FLOW_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });
  dispatch({
    type: DEBY_EQ_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });
  dispatch({
    type: EXPENSE_CNTRL_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });
  dispatch({
    type: EBIDTA_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: MIS_MATCH_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });
  dispatch({
    type: TRENDS_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  if (res.data.data.length) {
    res.data.data.forEach((note) => {
      if (note.key === "trendsNotes") {
        dispatch({
          type: TRENDS_NOTES,
          payload: {
            val: note.value,
            date: getGreaterDate(note.updated_at, note.updatedAt),
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "trend",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "trend",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "expenseControlNotes") {
        dispatch({
          type: EXPENSE_CNTRL_NOTES,
          payload: {
            val: note.value,
            date: getGreaterDate(note.updated_at, note.updatedAt),
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "exCntrl",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "exCntrl",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "debtEqRatioNotes") {
        dispatch({
          type: DEBY_EQ_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "debtEquity",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "debtEquity",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "ebidtaNotes") {
        dispatch({
          type: EBIDTA_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "ebitda",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "ebitda",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "misMatchNotes") {
        dispatch({
          type: MIS_MATCH_NOTES,
          payload: {
            val: note.value,
            date: formateDate(note.updated_at || note.updatedAt),
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "misMatch",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "misMatch",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "cashFlowActvtyNotes") {
        dispatch({
          type: CASH_FLOW_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "cashFlowAct",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "cashFlowAct",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }
    });
  } else {
    dispatch({
      type: CASH_FLOW_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: DEBY_EQ_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: EXPENSE_CNTRL_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: EBIDTA_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: MIS_MATCH_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: TRENDS_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
  }
};

export const fetchStartWithEndNotes = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

  if (res.data.data.length) {
    dispatch({
      type: START_WITH_END_NOTES,
      payload: {
        val: res.data.data[0].value,
        date: res.data.data[0].updatedAt || res.data.data[0].updated_at,
      },
    });

    dispatch({
      type: "SAVE_NOTES_PDF",
      payload: {
        key: "estimateValSavingPlan",
        value: res.data.data[0].value,
      },
    });

    dispatch({
      type: "UPDATE_NOTES_DATE_PDF",
      payload: {
        key: "estimateValSavingPlan",
        value: formateDate(
          res.data.data[0].updated_at || res.data.data[0].updatedAt
        ),
      },
    });
  }
};

export const fetchSimpleValNotes = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

  if (res.data.data.length) {
    dispatch({
      type: SIMPLE_VAL_NOTES,
      payload: {
        val: res.data.data[0].value,
        date: res.data.data[0].updatedAt || res.data.data[0].updated_at,
      },
    });

    dispatch({
      type: "SAVE_NOTES_PDF",
      payload: {
        // key: "cashFlowAct",
        key: "simpleVal",
        value: res.data.data[0].value,
      },
    });

    dispatch({
      type: "UPDATE_NOTES_DATE_PDF",
      payload: {
        key: "simpleVal",
        // key: "cashFlowAct",
        value: formateDate(
          res.data.data[0].updated_at || res.data.data[0].updatedAt
        ),
      },
    });
  }
};

export const fetchFinancialDoctorNotes = (payload) => async (dispatch) => {
  const res = await axios.post(`${BASE_URL}/notes/fetch`, payload);

  dispatch({
    type: CURRENT_RATIO_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: QUICK_RATIO_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: DEBT_EQUITY_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: GROSS_MARGIN_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: NET_MARGIN_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: SALE_ASSETS_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: EBIDTA_FD_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: ROA_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: ROI_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: INV_TURNOVER_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: AR_TURNOVER_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: AP_TURNOVER_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: DCOH_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  dispatch({
    type: DSCR_NOTES,
    payload: {
      val: "",
      date: "",
    },
  });

  if (res.data.data.length) {
    res.data.data.forEach((note) => {
      if (note.key === "currentRatioChart") {
        dispatch({
          type: CURRENT_RATIO_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "currentRatioChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "currentRatioChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "quickRatioChart") {
        dispatch({
          type: QUICK_RATIO_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "quickRatioChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "quickRatioChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "debtEqRatioChart") {
        dispatch({
          type: DEBT_EQUITY_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "debtEqRatioChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "debtEqRatioChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "grossMarginChart") {
        dispatch({
          type: GROSS_MARGIN_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "grossMarginChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "grossMarginChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "netMarginChart") {
        dispatch({
          type: NET_MARGIN_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "netMarginChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "netMarginChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "saleAssetsChart") {
        dispatch({
          type: SALE_ASSETS_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "saleAssetsChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "saleAssetsChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "ebidtaFDChart") {
        dispatch({
          type: EBIDTA_FD_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "ebidtaFDChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "ebidtaFDChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "roaChart") {
        dispatch({
          type: ROA_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "roaChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "roaChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "roiChart") {
        dispatch({
          type: ROI_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "roiChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "roiChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "invTurnoverChart") {
        dispatch({
          type: INV_TURNOVER_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "invTurnoverChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "invTurnoverChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "arTurnoverChart") {
        dispatch({
          type: AR_TURNOVER_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "arTurnoverChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "arTurnoverChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "apTurnoverChart") {
        dispatch({
          type: AP_TURNOVER_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "apTurnoverChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "apTurnoverChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key === "dcohChart") {
        dispatch({
          type: DCOH_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "dcohChart",
            value: note.value,
          },
        });

        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "dcohChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }

      if (note.key == "dscrChart") {
        dispatch({
          type: DSCR_NOTES,
          payload: {
            val: note.value,
            date: note.updatedAt || note.updated_at,
          },
        });

        dispatch({
          type: "SAVE_NOTES_PDF",
          payload: {
            key: "dscrChart",
            value: note.value,
          },
        });
        dispatch({
          type: "UPDATE_NOTES_DATE_PDF",
          payload: {
            key: "dscrChart",
            value: formateDate(note.updated_at || note.updatedAt),
          },
        });
      }
    });
  } else {
    dispatch({
      type: CURRENT_RATIO_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: QUICK_RATIO_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: DEBT_EQUITY_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: GROSS_MARGIN_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: NET_MARGIN_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: SALE_ASSETS_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: EBIDTA_FD_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: ROA_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
    dispatch({
      type: ROI_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: INV_TURNOVER_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: AR_TURNOVER_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: AP_TURNOVER_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: DCOH_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });

    dispatch({
      type: DSCR_NOTES,
      payload: {
        val: "",
        date: "",
      },
    });
  }
};
