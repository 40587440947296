import React, { useEffect, useState } from "react";
import "./style.css";
import similarity from "string-similarity";
import { Layout } from "../../layout";
import { CardWrapper, HomeTable } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import FilterDownArrow from "@mui/icons-material/KeyboardArrowDown";
import {
  Grid,
  Button,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  ListItemText,
  Box,
  Checkbox,
  Modal,
  Select,
  styled,
  Popover,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import CheckBox from "../../components/checkbox/index.jsx";
import {
  fetchCompanies,
  addNewCompany,
  updateCompany,
  setFilteredCompanies,
  clearFilteredCompanies,
  fetchSubAndMemEmails,
  updateCompanyAccess,
  naicsLookup,
} from "../../redux/actions/company.js";
import { useNavigate } from "react-router-dom";
import currency_symbols from "../../utils/currency.js";
import { industries } from "../../utils/industries";
import SearchIcon from "../../assets/search-icon.svg";
import {
  fetchUserById,
  fetchTeamMembers,
  fetchClients,
} from "../../redux/actions/user.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  clearSnack,
  handlInfo,
  clientCheck,
} from "../../utils/utilFunctions.js";
import SnackBar from "../../components/snackBar";
import { FormControlLabel } from "@material-ui/core";
import NAICSLogo from "../../images/naicslogo.png";

//
import { Autocomplete, TextField } from "@mui/material";
//

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove border
    },
    "&:hover fieldset": {
      border: "none", // Remove border on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove border when focused
    },
  },
  "& .MuiInputBase-root": {
    // Change background color
    backgroundColor: theme.palette.background.paper, // or any color you want
  },
  // Optional: If you want to change the focused state background
  "&:focus": {
    backgroundColor: theme.palette.background.paper, // or any color you want
  },
}));

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [availableNAICS, setAvailableNAICS] = useState([]);
  const [filteredAvailableNAICS, setFilteredAvailableNAICS] = useState([]);
  const [searchNAICS, setSearchNAICS] = useState("");

  const [openModel, setOpenModel] = useState(false);
  const [industry, setIndustry] = useState("");
  const [currency, setCurrency] = useState("");
  const [isService, setIsService] = useState(false);
  const [sendCompanyId, setSendCompanyId] = useState("");
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState({
    companyName: "",
    code: "",
  });

  const [newMembers, setNewMembers] = useState([]);
  const [newClients, setNewClients] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [shareUpdateLoading, setShareUpdateLoading] = useState(false);

  const [anchorFilter, setAnchorFilter] = useState(false);

  const [filters, setFilters] = useState({
    service: false,
    NAICS: false,
    sharedWithClient: false,
    sharedWithMembers: false,
    sharedWithSubs: false,
    // date: false,
  });

  const [members, setMembers] = useState([]);
  const [clients, setClients] = useState([]);

  const [isShowShareModal, setShowShareModal] = useState(false);
  const [fetchSubAndMemEmailsLoading, setFetchSubAndMemEmailsLoading] =
    useState(false);
  const [selectedShareComp, setSelectShareComp] = useState();

  const [allSubs, setAllSubs] = useState([]);
  const [allMem, setAllMem] = useState([]);
  const [allClnts, setAllClnts] = useState([]);

  const [selectedSubs, setSelectedSubs] = useState([]);
  const [selectedMems, setSelectedMems] = useState([]);
  const [selectedClnts, setSelectedClnts] = useState([]);

  console.log("selectedSubs", selectedSubs);

  const handleChangeSelectedSubs = (event, newValue) => {
    setSelectedSubs(newValue);
  };

  const handleChangeSelectedMems = (event, newValue) => {
    setSelectedMems(newValue);
  };

  const handleChangeSelectedClnts = (e, newValue) => {
    setSelectedClnts(newValue);
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleTooltipClose = () => {
    setSearchNAICS("");
    setAnchorEl_(null);
    setAvailableNAICS([]);
  };

  const openTooltip = Boolean(anchorEl_);
  const tooltipId = openTooltip ? "naics-tooltip" : undefined;

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    filteredCompanies,
    comapnies,
    compLoading,
    selectedCompany,
    editCompLoading,
  } = useSelector((state) => state.companyReducer);

  const { user, userEmail } = useSelector((state) => state.userReducer);

  const fetchMembersClient = async () => {
    const membersTeam = await dispatch(fetchTeamMembers());
    const allClients = await dispatch(fetchClients());
    setMembers(membersTeam);
    setClients(allClients);
  };

  useEffect(() => {
    fetchMembersClient();
  }, []);

  useEffect(() => {}, [selectedCompany]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let PStatus = localStorage.getItem("paymentStatus");
    let cpcp_subscribed = localStorage.getItem("cpcp_subscribed");

    if (
      (!token || token === "undefined") &&
      (!PStatus || PStatus !== "true") &&
      (!cpcp_subscribed || cpcp_subscribed !== "true")
    ) {
      navigate("/");
    } else if (PStatus === "false" && cpcp_subscribed === "false") {
      navigate("/subscription");
    } else {
      if (!Object.keys(user).length) {
        dispatch(fetchUserById({ userId: localStorage.getItem("id") }));
      }
      dispatch(
        fetchCompanies({ userId: localStorage.getItem("id"), userEmail })
      );
    }
  }, [navigate]);

  const filterData = (val) => {
    let filteredResults = [];

    switch (val) {
      case "service":
        filteredResults = comapnies
          .sort((a, b) => a.isService - b.isService)
          .reverse();

        break;

      case "NAICS":
        filteredResults = comapnies.sort(
          (a, b) => parseInt(a.NAICScode) - parseInt(b.NAICScode)
        );
        break;

      case "sharedWithClient":
        filteredResults = comapnies
          .sort(
            (a, b) =>
              a.clntsShareWithEmail.length - b.clntsShareWithEmail.length
          )
          .reverse();

        break;

      case "sharedWithMembers":
        filteredResults = comapnies
          .sort((a, b) => a.sharedWithEmail.length - b.sharedWithEmail.length)
          .reverse();

        break;

      case "sharedWithSubs":
        filteredResults = comapnies
          .sort(
            (a, b) =>
              a.subsSharedWithEmail.length - b.subsSharedWithEmail.length
          )
          .reverse();

        break;

      default:
        break;
    }

    dispatch(setFilteredCompanies(filteredResults));
  };

  const handleSubmit = async () => {
    if (!state.companyName || !state.code || !currency || !industry) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "All fields required",
        },
      });
      clearSnack(dispatch);
      return;
    }

    const payload = {
      companyName: state.companyName,
      NAICScode: state.code,
      userId: localStorage.getItem("id"),
      currency: currency,
      industry: industry,
      sharedWithEmail: newMembers,
      clntsShareWithEmail: newClients,
      isService,
    };
    const respo = await dispatch(addNewCompany(payload));

    if (respo.availableNAICS) {
      setAvailableNAICS(respo.availableNAICS);
      setAnchorEl_(true);
    }

    if (typeof respo === "boolean" && respo) {
      handleModelClose();
      setNewMembers([]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleEditValues = async () => {
    let companyId = sendCompanyId;

    const payload = {
      companyName: state.companyName,
      currency: currency ? currency : selectedCompany.currency,
      NAICScode: state.code ? state.code : selectedCompany.NAICScode,
      industry: industry ? industry : selectedCompany.industry,
      sharedWithEmail: newMembers,
      clntsShareWithEmail: newClients,
      isService,
    };

    const respo = await dispatch(updateCompany(payload, companyId));

    if (respo.availableNAICS) {
      setAvailableNAICS(respo.availableNAICS);
      setAnchorEl_(true);
    }

    if (typeof respo === "boolean" && respo) {
      if (respo) handleModelClose();

      if (selectedCompany?._id === companyId) {
        dispatch({
          type: "UPDATE_SELECTED_COMP_DETAIL",
          payload: { ...selectedCompany, ...payload },
        });
      }
    }
  };

  const handleModelClose = () => {
    setState({
      companyName: "",
      code: "",
    });
    setOpenModel(false);
    setNewMembers([]);
  };

  const handleShareModalClose = () => {
    setShowShareModal(false);
    setSelectedMems([]);
    setSelectedSubs([]);
    setSelectedClnts([]);
  };

  const handleOpen = () => {
    setOpenModel(true);
  };

  const handleOpenModal = () => {
    if (!clientCheck(user, dispatch)) {
      return;
    }
    setEdit(false);
    handleOpen();
  };

  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const hanldeFilters = (val) => {
    let newFilter = { ...filters };

    Object.keys(filters).forEach((f) => {
      if (f === val) {
        newFilter = { ...newFilter, [f]: true };
      } else {
        newFilter = { ...newFilter, [f]: false };
      }
    });

    filterData(val);

    setFilters(newFilter);
  };

  const handleNoMember = () => {
    if (members && members.length) {
      for (let i = 0; i < members?.length; i++) {
        if (members[i].isSignedUp) {
          i = members.length;
          return false;
        }
        if (i < members.length - 1) {
          return true;
        }
      }
    }
    return true;
  };

  const handleNoClients = () => {
    if (clients && clients.length) {
      for (let i = 0; i < clients?.length; i++) {
        if (clients[i].isSignedUp) {
          i = clients.length;
          return false;
        }
        if (i < clients.length - 1) {
          return true;
        }
      }
    }
    return true;
  };

  const handleNewMemberToggle = (item) => {
    let dummySelected = [...newMembers];

    if (newMembers.includes(item)) {
      dummySelected = dummySelected.filter((ds) => ds !== item);
    } else {
      dummySelected = [...dummySelected, item];
    }

    setNewMembers(dummySelected);
  };

  const handleNewClientToggle = (item) => {
    let dummySelected = [...newClients];

    if (newClients.includes(item)) {
      dummySelected = dummySelected.filter((ds) => ds !== item);
    } else {
      dummySelected = [...dummySelected, item];
    }

    setNewClients(dummySelected);
  };

  const handleShareWithButton = async (item) => {
    if (!clientCheck(user, dispatch)) {
      return;
    }
    setShowShareModal(true);
    setSelectShareComp(item);

    setFetchSubAndMemEmailsLoading(true);

    const response = await dispatch(fetchSubAndMemEmails(item.userId));

    setAllMem(response.allMem);
    setAllSubs(response.allSubs);
    setAllClnts(response.allClients);

    setFetchSubAndMemEmailsLoading(false);

    setSelectedMems(
      item.sharedWithEmail.map((mem) => ({ label: mem, value: mem }))
    );
    setSelectedSubs(
      item?.subsSharedWithEmail?.map((sub) => ({ label: sub, value: sub })) || [
        "",
      ]
    );

    setSelectedClnts(
      item?.clntsShareWithEmail?.map((clnt) => ({
        label: clnt,
        value: clnt,
      })) || [""]
    );
  };

  // const handleUpdateSharedMemberList = () => {
  //   const payload = {
  //     updatedComp: {
  //       ...memberComp,
  //       sharedWithEmail: selectedMembers,
  //     },
  //     userEmail,
  //   };

  //   dispatch(updateCompanyAccess(payload));
  // };

  const handleShareSubmit = async () => {
    const subs = selectedSubs.map((sub) => sub.value);
    const mems = selectedMems.map((mem) => mem.value);
    const clnts = selectedClnts.map((clnt) => clnt.value);

    setShareUpdateLoading(true);

    const payload = {
      updatedComp: {
        ...selectedShareComp,
        sharedWithEmail: mems,
        subsSharedWithEmail: subs,
        clntsShareWithEmail: clnts,
      },
      userEmail,
    };

    await dispatch(updateCompanyAccess(payload));
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "success",
        message: "Sharing updated",
      },
    });
    clearSnack(dispatch);

    setShareUpdateLoading(false);
    handleShareModalClose();
  };

  const handleLookupNAICSSelection = (naicsCode) => {
    setState({ ...state, code: naicsCode });
  };

  const fetchLookupNAICS = async (state) => {
    const { code } = state;

    const resp = await dispatch(naicsLookup({ naicsCode: code }));

    setAvailableNAICS(resp);
    if (searchNAICS) setFilteredAvailableNAICS(resp);
    setAnchorEl_(true);
  };

  const handleNAICSLookupSearch = (e) => {
    setSearchNAICS(e.target.value);
    const dummyAvailableNAICS = [...availableNAICS];

    const filteredNAICS = dummyAvailableNAICS.filter((item) =>
      item["NAICS CODE"].toString().includes(e.target.value)
    );

    setFilteredAvailableNAICS(filteredNAICS);
    if (filteredNAICS && !filteredNAICS?.length) {
      fetchLookupNAICS({ code: e.target.value });
    }
  };

  return (
    <React.Fragment>
      <Layout title="Home">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              height: "calc(100vh - 208px)",
            }}
            className="hide_sideBar home_main"
          >
            {compLoading ? (
              <CircularProgress />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // marginBottom: 30,
                      height: 50,
                    }}
                  >
                    <Typography className="home_my_companies">
                      My Companies{" "}
                      <InfoOutlinedIcon
                        onClick={() => handlInfo("company")}
                        className="infor_icon"
                      />
                    </Typography>
                    <Grid
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div>
                        <Button
                          onClick={handleFilterClick}
                          className="home_filter"
                        >
                          {/* <span style={{ fontSize: 30, marginRight: 10 }}>
                            +
                          </span>{" "} */}

                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "200px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <CiFilter className="filter_icon" />
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Filter
                              </p>
                            </div>

                            <FilterDownArrow className="filter_icon" />
                          </Grid>
                        </Button>
                        <Menu
                          anchorEl={anchorFilter}
                          open={Boolean(anchorFilter)}
                          onClose={() => setAnchorFilter(false)}
                        >
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <div style={{ padding: "5px" }}>
                                  <CheckBox
                                    checked={filters.service}
                                    onClick={() => hanldeFilters("service")}
                                  />
                                </div>
                              }
                              label="Service Company"
                            />
                          </MenuItem>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <div style={{ padding: "5px" }}>
                                  <CheckBox
                                    checked={filters.NAICS}
                                    onClick={() => hanldeFilters("NAICS")}
                                  />
                                </div>
                              }
                              label="NAICS code"
                            />
                          </MenuItem>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <div style={{ padding: "5px" }}>
                                  <CheckBox
                                    checked={filters.sharedWithSubs}
                                    onClick={() =>
                                      hanldeFilters("sharedWithSubs")
                                    }
                                  />
                                </div>
                              }
                              label="Shared with subscribers"
                            />
                          </MenuItem>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <div style={{ padding: "5px" }}>
                                  <CheckBox
                                    checked={filters.sharedWithClient}
                                    onClick={() =>
                                      hanldeFilters("sharedWithClient")
                                    }
                                  />
                                </div>
                              }
                              label="Shared with clients"
                            />
                          </MenuItem>
                          <MenuItem>
                            <FormControlLabel
                              control={
                                <div style={{ padding: "5px" }}>
                                  <CheckBox
                                    checked={filters.sharedWithMembers}
                                    onClick={() =>
                                      hanldeFilters("sharedWithMembers")
                                    }
                                  />
                                </div>
                              }
                              label="Shared with members"
                            />
                          </MenuItem>
                        </Menu>
                      </div>
                      {
                        <Button
                          onClick={handleOpenModal}
                          className={
                            user.isClient
                              ? "disabled_home_add_company"
                              : "home_add_company"
                          }
                        >
                          <span style={{ fontSize: 30, marginRight: 10 }}>
                            +
                          </span>{" "}
                          Add Company
                        </Button>
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      height: "calc(100% - 80px)",
                    }}
                  >
                    <CardWrapper border="4px solid #0C8CE9">
                      <HomeTable
                        comapnies={comapnies}
                        selectedCompany={selectedCompany}
                        handleOpen={handleOpen}
                        setEdit={setEdit}
                        setState={setState}
                        filteredCompanies={filteredCompanies}
                        setSendCompanyId={setSendCompanyId}
                        members={members}
                        handleNoMember={handleNoMember}
                        handleNoClients={handleNoClients}
                        setNewMembers={setNewMembers}
                        handleShareWithButton={handleShareWithButton}
                      />
                    </CardWrapper>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </CardWrapper>
      </Layout>

      <Modal
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter Details</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Company Name
              </Typography>
              {!edit ? (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    style={{ padding: "0px" }}
                    onChange={(e) => setIsService(e.target.checked)}
                  />{" "}
                  <p style={{ marginTop: "10px" }}>Service </p>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    style={{ padding: "0px" }}
                    defaultChecked={state.isService}
                    onChange={(e) => setIsService(e.target.checked)}
                  />{" "}
                  <p style={{ marginTop: "10px" }}>Service </p>
                </div>
              )}
            </div>

            <input
              className="customize-input1"
              placeholder="Enter Company Name"
              color="white"
              name="companyName"
              type="text"
              value={state.companyName}
              onChange={handleChange}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                NAICS Code
              </Typography>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Currency
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <input
                className="customize-input1"
                placeholder="Enter Code here i.e 346242"
                color="white"
                name="code"
                type="text"
                value={state.code}
                onChange={handleChange}
              />

              <FormControl className="currencies-form">
                <Select
                  defaultValue={state.currency}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                  sx={{ border: "1px solid", borderRadius: "22px" }}
                >
                  {Object.keys(currency_symbols).map((keys, index) => {
                    return (
                      <MenuItem key={index} value={keys}>
                        {currency_symbols[keys]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <p
                className="findNaicsBtn"
                onClick={() => {
                  fetchLookupNAICS(state);
                }}
              >
                Find NAICS
              </p>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Industry
              </Typography>
            </Box>
            <FormControl className="industry-form">
              <Select
                defaultValue={state.industry}
                label="Industry"
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
                sx={{ border: "1px solid", borderRadius: "22px" }}
              >
                {Object.keys(industries).map((keys, index) => {
                  return (
                    <MenuItem key={index} value={keys}>
                      {industries[keys]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Members
              </Typography>
            </Box>
            <FormControl className="industry-form">
              <Select
                // defaultValue={state.industry}
                label="Members"
                // onChange={(e) => {
                //   setIndustry(e.target.value);
                // }}
                sx={{ border: "1px solid", borderRadius: "22px" }}
                multiple
                value={newMembers}
                defaultValue={newMembers}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null, // Required to disable default anchoring
                }}
              >
                {handleNoMember() ? (
                  <p
                    style={{
                      padding: "10px",
                      color: "gray",
                    }}
                  >
                    No member added yet
                  </p>
                ) : (
                  members?.map((member) => {
                    if (member.isSignedUp)
                      return (
                        <MenuItem
                          onClick={() =>
                            handleNewMemberToggle(member.memberEmail)
                          }
                        >
                          <CheckBox
                            style={{ backgroundColor: "white" }}
                            checked={newMembers.includes(member.memberEmail)}
                          />
                          <ListItemText primary={member.memberEmail} />
                        </MenuItem>
                      );
                  })
                )}
              </Select>
            </FormControl>

            {/* Client selection , need to be done */}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Clients
              </Typography>
            </Box>
            <FormControl className="industry-form">
              <Select
                label="Clients"
                sx={{ border: "1px solid", borderRadius: "22px" }}
                multiple
                value={newClients}
                defaultValue={newClients}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null, // Required to disable default anchoring
                }}
              >
                {handleNoClients() ? (
                  <p
                    style={{
                      padding: "10px",
                      color: "gray",
                    }}
                  >
                    No clients added yet
                  </p>
                ) : (
                  clients?.map((clnt) => {
                    if (clnt.isSignedUp)
                      return (
                        <MenuItem
                          onClick={() =>
                            handleNewClientToggle(clnt.clientEmail)
                          }
                        >
                          <CheckBox
                            style={{ backgroundColor: "white" }}
                            checked={newClients.includes(clnt.clientEmail)}
                          />
                          <ListItemText primary={clnt.clientEmail} />
                        </MenuItem>
                      );
                  })
                )}
              </Select>
            </FormControl>

            {/*  */}
            {/*  */}

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                disabled={editCompLoading}
                onClick={!edit ? handleSubmit : handleEditValues}
              >
                Submit
                {editCompLoading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5%",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* Tooltip Popover */}
      <Popover
        id={tooltipId}
        open={openTooltip}
        // anchorEl={anchorEl}
        onClose={handleTooltipClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 375, left: 250 }}
        PaperProps={{
          sx: {
            padding: 2,
            maxWidth: 350,
            borderRadius: 2,
            boxShadow: 3,
            position: "relative",
            marginleft: 300,
            maxHeight: 700,
          },
        }}
      >
        {/* Close button and Tooltip content */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid display={"flex"} alignItems={"center"} marginBottom={"22px"}>
            <img src={NAICSLogo} alt="NAICSLogo" className="naics_logo" />
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginLeft: "10px" }}
              fontWeight="bold"
            >
              Lookup Assistant
            </Typography>
          </Grid>

          <IconButton onClick={handleTooltipClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant="body1"
          sx={{
            paddingBottom: "5px",
          }}
        >
          Data Sets Available
        </Typography>
        <Box
          sx={{
            backgroundColor: "#bababa",
            height: "2px",
          }}
        ></Box>

        <Box className="header_search_bar2">
          <img src={SearchIcon} alt="search-icon" style={{ marginRight: 10 }} />
          <input
            className="popover_input"
            type="text"
            placeholder="Enter NAICS code"
            onChange={handleNAICSLookupSearch}
          />
        </Box>
        <List sx={{ mt: 1, maxHeight: 400, overflowY: "auto" }}>
          {searchNAICS ? (
            filteredAvailableNAICS.length ? (
              filteredAvailableNAICS.map((item) => (
                <ListItem
                  button
                  key={item["NAICS CODE"]}
                  onClick={() => handleLookupNAICSSelection(item["NAICS CODE"])}
                >
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold">
                        {item["NAICS CODE"]}
                      </Typography>
                    }
                    secondary={item["IndTitle"]}
                  />
                </ListItem>
              ))
            ) : (
              <p>No data</p>
            )
          ) : availableNAICS.length ? (
            availableNAICS.map((item) => (
              <ListItem
                button
                key={item["NAICS CODE"]}
                onClick={() => handleLookupNAICSSelection(item["NAICS CODE"])}
              >
                <ListItemText
                  primary={
                    <Typography fontWeight="bold">
                      {item["NAICS CODE"]}
                    </Typography>
                  }
                  secondary={item["IndTitle"]}
                />
              </ListItem>
            ))
          ) : (
            ""
          )}
        </List>
      </Popover>

      <Modal open={isShowShareModal} onClose={handleShareModalClose}>
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Share Company</h2>

          {!fetchSubAndMemEmailsLoading ? (
            <>
              <Typography className="select_heading">
                Subscribers <b>({selectedSubs?.length})</b>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                }}
              >
                <Autocomplete
                  multiple
                  value={selectedSubs}
                  options={
                    allSubs.length
                      ? allSubs
                          .filter((sub) => sub !== userEmail) // Exclude userEmail
                          .map((sub) => ({ label: sub, value: sub }))
                      : []
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  onChange={handleChangeSelectedSubs}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  } // Ensure proper select/deselect
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => {
                      if (
                        selectedSubs.find(
                          (slctd) => slctd.value === option.value
                        )
                      )
                        return option;

                      const input = inputValue.toLowerCase();
                      const label = option.label.toLowerCase();
                      const similarityScore = similarity.compareTwoStrings(
                        input,
                        label
                      );
                      return similarityScore >= 0.75; // Only show options with 75% or more similarity
                    });
                  }}
                  noOptionsText="Enter email of subscriber" // Message when no matches found
                  renderOption={(props, option, { selected }) => {
                    return option?.label ? (
                      <li {...props}>
                        <CheckBox checked={selected} />
                        {option?.label}
                      </li>
                    ) : (
                      ""
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select Subscribers"
                    />
                  )}
                  renderTags={() => null}
                />
              </div>

              {/*  */}

              <Typography className="select_heading">
                Team Members <b>({selectedMems?.length})</b>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                }}
              >
                <Autocomplete
                  multiple
                  value={selectedMems}
                  options={
                    allMem.length
                      ? allMem.map((sub) => {
                          if (sub !== userEmail)
                            return { label: sub, value: sub };
                        })
                      : []
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  onChange={handleChangeSelectedMems}
                  renderOption={(props, option, { selected }) => {
                    return option?.label ? (
                      <li {...props}>
                        <CheckBox checked={selected} />
                        {option?.label}
                      </li>
                    ) : (
                      ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  } // Ensure proper select/deselect
                  style={{
                    width: "100%",
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select Team Members"
                    />
                  )}
                  renderTags={() => null}
                />
              </div>

              <Typography className="select_heading">
                Clients <b>({selectedClnts?.length})</b>
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                }}
              >
                <Autocomplete
                  multiple
                  value={selectedClnts}
                  options={
                    allClnts.length
                      ? allClnts.map((sub) => {
                          if (sub !== userEmail)
                            return { label: sub, value: sub };
                        })
                      : []
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  onChange={handleChangeSelectedClnts}
                  renderOption={(props, option, { selected }) => {
                    return option?.label ? (
                      <li {...props}>
                        <CheckBox checked={selected} />
                        {option?.label}
                      </li>
                    ) : (
                      ""
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  } // Ensure proper select/deselect
                  style={{
                    width: "100%",
                  }}
                  renderInput={(params) => (
                    <CustomTextField {...params} placeholder="Select Clients" />
                  )}
                  renderTags={() => null}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <Button
                  onClick={handleShareModalClose}
                  className="share_modal_button"
                >
                  Close
                </Button>
                <Button
                  onClick={handleShareSubmit}
                  className="share_modal_button"
                >
                  Update
                  {shareUpdateLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "5%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                minHeight: "250px",
                display: "flex",
                alignItems: "center",
                marginLeft: "19vh",
              }}
            >
              <CircularProgress
                color="inherit"
                style={{
                  height: "40px",
                  width: "40px",
                  marginLeft: "10%",
                }}
              />
            </div>
          )}
        </Box>
      </Modal>

      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </React.Fragment>
  );
};
export default Home;
