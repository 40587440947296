import { useEffect } from "react";
import {
  Typography,
  Box,
  MenuItem,
  TextField,
  Grid,
  Button,
  Select,
} from "@mui/material";
import CurrencyInput from "../CurrencyInput";
import RemoveIcon from "@mui/icons-material/Remove";
import PieChart from "./pieChart";
import { clientCheck, removeComma } from "../../utils/utilFunctions";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png";
import CircularProgress from "@material-ui/core/CircularProgress";

const CalculateTrans = ({
  dream,
  setDream,
  needInFuture,
  setNeedInFuture,
  setNeedYears,
  needYears,
  yearsUntilExit,
  setYearsUntilExit,
  priorSaving,
  setPriorSaving,
  handleSave,
  tvGoal,
  currSavingGoal,
  handleTransferableValueGoal,
  handleCurrSavingGoal,
  handleMonthlyGoal,
  formateWithCurrency,
  currency,
  handleTVYearGoal,
  handleScenarioModalOpen,
  dispatch,
  user,
  startWithEndData,
  setSelectedScenario,
  selectedScenario,
  handleSelectScenario,
  handleClickEditScenario,
  handleDeleteScenario,
  setScenarioName,
  setScenarioDate,
  setScenarioIsIncludeSales,
  setIsScenEdit,
}) => {
  useEffect(() => {}, [selectedScenario?._id]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid
          display="flex"
          justifyContent="space-between"
          alignContent={"center"}
          paddingBottom="20px"
        >
          <Typography variant="h6" fontWeight="bold" mb={4}>
            Calculate Transferable Value Goal
          </Typography>
          {/*  */}
          <Button
            onClick={() => {
              if (!clientCheck(user, dispatch)) {
                return;
              }

              setScenarioName("");
              setScenarioDate("");
              setScenarioIsIncludeSales(false);
              handleScenarioModalOpen();
              setIsScenEdit(false);
            }}
            className={user.isClient ? "disabled-scenario-btn" : "scenario-btn"}
          >
            <span style={{ fontSize: 30, marginRight: 10 }}>+</span> Scenario
          </Button>
          {/*  */}
        </Grid>
        <Select
          onChange={handleSelectScenario}
          value={selectedScenario?._id}
          defaultValue={selectedScenario?._id}
          label="Scenario"
          className="financial_data_select"
          sx={{ float: "right", margin: "0px 0 10px 10px" }}
        >
          {startWithEndData?.map((scen, index) => {
            return (
              <MenuItem
                className="adjust_period_menu_icons"
                key={index}
                value={scen._id}
              >
                {scen.scenarioName}
                <span className="adjust-btn-size">
                  <Button
                    onClick={() => {
                      if (!clientCheck(user, dispatch)) {
                        return;
                      }
                      handleClickEditScenario(scen);
                    }}
                  >
                    <img src={Edit} alt="" height={20} />
                  </Button>
                  <Button
                    onClick={() => {
                      if (!clientCheck(user, dispatch)) {
                        return;
                      }
                      handleDeleteScenario(scen);
                    }}
                  >
                    <img src={Delete} alt="" height={20} />
                  </Button>
                </span>
              </MenuItem>
            );
          })}
        </Select>

        {/* Question 1 */}
        <Box mb={3}>
          <Typography variant="body1" gutterBottom>
            What are you looking to accomplish next? (retire, travel, pay off
            debt, grow the business, acquire a new company…?)
          </Typography>
          <TextField
            disabled={selectedScenario?.scenarioName ? false : true}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: { backgroundColor: "#e6e6e6", padding: "10px" },
            }}
            value={dream}
            onChange={(e) => setDream(e.target.value)}
          />
        </Box>

        {/* Question 2 */}
        <Box mb={3} display={"flex"}>
          <Grid
            style={{ width: "-webkit-fill-available", paddingRight: "10px" }}
          >
            <Typography variant="body1" gutterBottom>
              Amount needed to support this goal per year or once.
            </Typography>
            <Box className="curr_input_box">
              <Box className="curr_prefix">{currency ? currency : "$"}</Box>

              <CurrencyInput
                disabled={selectedScenario?.scenarioName ? false : true}
                className="need_input"
                type="text"
                value={needInFuture}
                onChange={(e) => {
                  setNeedInFuture(e.target.value);
                  let newTvGoal = handleTransferableValueGoal(
                    needInFuture,
                    e.target.value
                  );
                  handleCurrSavingGoal(newTvGoal, priorSaving);
                  handleMonthlyGoal(currSavingGoal, yearsUntilExit);
                }}
              />
            </Box>
          </Grid>
          <Grid style={{ width: "-webkit-fill-available" }}>
            <Typography variant="body1" gutterBottom>
              Number of years this amount needs to support. Multiple or One
              Time?
            </Typography>
            <Box className="curr_input_box">
              <TextField
                disabled={selectedScenario?.scenarioName ? false : true}
                variant="outlined"
                fullWidth
                sx={{ height: "85px" }}
                InputProps={{
                  sx: {
                    backgroundColor: "#e6e6e6",
                    padding: "10px",
                    height: "110px",
                  },
                }}
                type="number"
                value={needYears}
                onChange={(e) => {
                  setNeedYears(e.target.value);
                  let newTvGoal = handleTransferableValueGoal(
                    needInFuture,
                    e.target.value
                  );
                  handleCurrSavingGoal(newTvGoal, priorSaving);
                  handleMonthlyGoal(currSavingGoal, yearsUntilExit);
                }}
              />
            </Box>
          </Grid>
        </Box>

        {/* Question 3 */}
        <Box mb={3}>
          <Typography variant="body1" gutterBottom>
            How many years do you have until you want to achieve this goal?
          </Typography>
          <TextField
            disabled={selectedScenario?.scenarioName ? false : true}
            variant="outlined"
            fullWidth
            type="number"
            value={yearsUntilExit}
            onChange={(e) => {
              setYearsUntilExit(e.target.value);
              handleMonthlyGoal(currSavingGoal, e.target.value);
            }}
            InputProps={{
              sx: { backgroundColor: "#e6e6e6", padding: "10px" },
            }}
            onWheel={(e) => e.target.blur()}
          />
        </Box>
        <Grid display={"flex"}>
          <Grid sm={8} md={8} lg={8}>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Typography variant="body1" gutterBottom>
                Transferable Value
              </Typography>
              <Typography variant="body1" gutterBottom>
                {tvGoal ? formateWithCurrency(parseInt(tvGoal)) : "0"}
              </Typography>
            </Grid>
            <Grid
              mt={4}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="body1" gutterBottom>
                Prior Saving
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    borderBottom: "2px solid gray",
                    paddingBottom: "15px",
                  }}
                >
                  <RemoveIcon />
                  <Box className="curr_input_box">
                    <Box className="prior_curr_prefix">
                      {currency ? currency : "$"}
                    </Box>
                    <CurrencyInput
                      disabled={selectedScenario?.scenarioName ? false : true}
                      className="prior_saving_input"
                      type="text"
                      value={priorSaving}
                      onChange={(e) => {
                        setPriorSaving(e.target.value);
                        handleCurrSavingGoal(tvGoal, e.target.value);
                        handleMonthlyGoal(currSavingGoal, yearsUntilExit);
                      }}
                    />
                  </Box>
                </Grid>
              </Typography>
            </Grid>
            <Grid display={"flex"} justifyContent={"space-between"} mt={4}>
              <Typography variant="body1" gutterBottom>
                Current Saving Goal
              </Typography>
              <Typography variant="body1" fontWeight={"bold"} gutterBottom>
                {currSavingGoal
                  ? formateWithCurrency(parseInt(currSavingGoal))
                  : "0"}
              </Typography>
            </Grid>
            <Grid display={"flex"} justifyContent={"space-between"} mt={4}>
              <Typography variant="body1" gutterBottom>
                I have saved
              </Typography>
              <CurrencyInput
                disabled={true}
                value={priorSaving ? priorSaving : 0}
                className="saved"
                type="text"
                maskOptions={{ prefix: currency ? currency : "$" }}
              />
            </Grid>
            <Grid display={"flex"} justifyContent={"space-between"} mt={4}>
              <Typography variant="body1" gutterBottom>
                I still need to save
              </Typography>
              <CurrencyInput
                disabled={true}
                value={currSavingGoal ? currSavingGoal : 0}
                className="need_to_save"
                type="text"
                maskOptions={{ prefix: currency ? currency : "$" }}
              />
            </Grid>
          </Grid>
          <Grid
            sm={4}
            md={4}
            lg={4}
            alignContent={"center"}
            sx={{ marginLeft: "10%" }}
          >
            <PieChart
              priorSaving={removeComma(priorSaving)}
              currSavingGoal={currSavingGoal}
              formateWithCurrency={formateWithCurrency}
            />
            <Button
              onClick={() => {
                if (!clientCheck(user, dispatch)) return;
                handleSave();
              }}
              className={
                user.isClient ? "disabled-save-btn-swe" : "save-btn-swe"
              }
              style={{ float: "right" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CalculateTrans;
