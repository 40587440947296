import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  MenuItem,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Analytics from "../../assets/analytics-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import YouVsIndustryAvg from "../../components/hiddenCash/youVSIndustryAvg";
import YouVSOwnGoal from "../../components/hiddenCash/youVSOwnGoal";
import { fetchHiddenCashNotes } from "../../redux/actions/notes";
import html2canvas from "html2canvas";
import { updatePDFData } from "../../redux/actions/pdfGraph";
import { handlInfo } from "../../utils/utilFunctions";
import currency_symbols from "../../utils/currency";

const HiddenCash = () => {
  const dispatch = useDispatch();

  const ownGoalRef = useRef(null);
  const induestryAvgRef = useRef(null);
  const comprtvRatioRef = useRef(null);

  const ownGoalNotesRef = useRef(null);
  const industryAvgNotesRef = useRef(null);
  const compRatioNotesRef = useRef(null);

  const {
    periods,
    industryAverage,
    financialData,
    company,
    companyDetailsLoading,
  } = useSelector((state) => state.companyDetailReducer);

  const { selectedCompany } = useSelector((state) => state.companyReducer);

  const { industryAvgGraphNotes, ownGoalGraphNotes, comparativeRatioNotes } =
    useSelector((state) => state.notesReducer);

  const [selectedDate, setSelectedDate] = useState("");
  const [periodData, setPeriodData] = useState("");
  const [isGraphUpdate, setIsGraphUpdate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      savePDFGraphs();
    }, 5000);
  }, [isGraphUpdate]);

  const Comparative_Ratios = [
    {
      title: "Sales to Assets",
      capmany: periodData?.result?.salesToAssets,
      owner_goal: periodData?.ownerGoal?.salesToAssets,
      industry_average: industryAverage?.salesToAssets,
    },
    {
      title: "Gross Profit Margin",
      capmany: `${periodData?.result?.grossProfitePercentage}%`,
      owner_goal: `${periodData?.ownerGoal?.grossProfitePercentage}%`,
      industry_average: `${industryAverage?.grossProfitePercentage}%`,
    },
    {
      title: "Net Profit Margin",
      capmany: `${periodData?.result?.netProfitePercentage}%`,
      owner_goal: `${periodData?.ownerGoal?.netProfitePercentage}%`,
      industry_average: `${industryAverage?.netProfitePercentage}%`,
    },
    {
      title: "Inventory Turnover",
      capmany: periodData?.result?.inventoryTurnoverRate,
      owner_goal: periodData?.ownerGoal?.inventoryTurnoverRate,
      industry_average: industryAverage?.inventoryTurnoverRate,
    },
    {
      title: "Accounts Receivable Turnover",
      capmany: periodData?.result?.accountsReceivableTurnoverRate,
      owner_goal: periodData?.ownerGoal?.accountsReceivableTurnoverRate,
      industry_average: industryAverage?.accountsReceivableTurnoverRate,
    },
    {
      title: "Accounts Payable Turnover",
      capmany: periodData?.result?.accountsPayableTurnoverRate,
      owner_goal: periodData?.ownerGoal?.accountsPayableTurnoverRate,
      industry_average: industryAverage?.accountsPayableTurnoverRate,
    },
  ];

  const savePDFGraphs = async () => {
    dispatch({
      type: "GRAPH_LOADING",
    });
    try {
      if (
        !ownGoalRef.current ||
        !induestryAvgRef.current ||
        !comprtvRatioRef.current
      ) {
        dispatch({
          type: "STOP_GRAPH_LOADING",
        });
        return;
      }

      const ownerGoalCanvas = await html2canvas(ownGoalRef.current);
      const ownerGoadBase64Image = await ownerGoalCanvas.toDataURL("image/png");

      const industyAvgCanvas = await html2canvas(induestryAvgRef.current);
      const industyAvgBase64Image = await industyAvgCanvas.toDataURL(
        "image/png"
      );

      const compRatioCanvas = await html2canvas(comprtvRatioRef.current);
      const compRatioBase64Image = await compRatioCanvas.toDataURL("image/png");

      // for notes
      const ownGoalNotesCanvas = await html2canvas(ownGoalNotesRef.current);
      const ownGoalNotesCanvasImage = await ownGoalNotesCanvas.toDataURL(
        "image/png"
      );

      const industryAvgNotesCanvas = await html2canvas(
        industryAvgNotesRef.current
      );
      const industryAvgNotesImage = await industryAvgNotesCanvas.toDataURL(
        "image/png"
      );

      const compRatioNotesCanvas = await html2canvas(compRatioNotesRef.current);
      const compRatioNotesImage = await compRatioNotesCanvas.toDataURL(
        "image/png"
      );

      const payload = {
        notes: {
          ownGoal: ownGoalNotesCanvasImage,
          industryAvg: industryAvgNotesImage,
          comprtvRatio: compRatioNotesImage,
        },
        images: {
          ownGoal: ownerGoadBase64Image,
          industryAvg: industyAvgBase64Image,
          comprtvRatio: compRatioBase64Image,
        },
      };

      dispatch(updatePDFData(payload));

      dispatch({
        type: "STOP_GRAPH_LOADING",
      });

      // setPdfGraph({
      //   ...pdfGraph,
      //   notes: {
      //     ...pdfGraph.notes,
      //     ownGoal: ownGoalNotesCanvasImage,
      //     industryAvg: industryAvgNotesImage,
      //     comprtvRatio: compRatioNotesImage,
      //   },
      //   images: {
      //     ...pdfGraph.images,
      //     ownGoal: ownerGoadBase64Image,
      //     industryAvg: industyAvgBase64Image,
      //     comprtvRatio: compRatioBase64Image,
      //   },
      // });

      // setPDFPageLoader(false);
    } catch (error) {
      console.log("error in savePDFGraphs", error);
    }
  };

  const handleChange = async (event) => {
    setSelectedDate(event.target.value);

    let periodData = financialData.find((x) => x.year === event.target.value);
    setPeriodData(periodData);

    const payload = {
      date: periodData.year,
      page: "HiddenCash",
      companyId: company._id,
    };

    dispatch(fetchHiddenCashNotes(payload));

    setTimeout(() => {
      savePDFGraphs();
    }, 5000);
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  return (
    <React.Fragment>
      <Layout title="Hidden Cash">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Typography className="financial_data_right_heading">
                NAICS Code: {company.NAICScode}
              </Typography>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Typography className="hidden_cash_subTitle">
            Mining for Hidden Cash{" "}
            <InfoOutlinedIcon
              onClick={() => handlInfo("hiddenCash")}
              className="infor_icon"
            />
          </Typography>
          <Select
            onChange={handleChange}
            value={selectedDate}
            label="years"
            className="financial_data_select"
          >
            {periods?.map((period, index) => {
              return (
                <MenuItem
                  className="adjust_period_menu_icons"
                  key={index}
                  value={period.date}
                >
                  {period.periodName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Box style={{ height: 10, width: "100%" }} />
        <CardWrapper>
          {!selectedDate ? (
            <CardWrapper border="1px solid #E7E7E7">
              <Typography style={{ color: "#bfbfbf" }}>
                Please Select Period
              </Typography>
            </CardWrapper>
          ) : (
            <>
              <CardWrapper border="1px solid #E7E7E7">
                <Grid container xs={12}>
                  {/* <Box style={{ height: 30, width: "100%" }} /> */}
                  {/* <Box style={{ height: 30, width: "100%" }} /> */}
                  {/* <Typography variant="h5">Graph Gows Here</Typography> */}
                  <Box style={{ height: "100%", width: "100%" }}>
                    {periodData ? (
                      <Box ref={ownGoalRef}>
                        <YouVSOwnGoal
                          selectedYearData={periodData?.financialImpactVsYou}
                          setIsGraphUpdate={setIsGraphUpdate}
                          isGraphUpdate={isGraphUpdate}
                          periodData={periodData}
                          industryAverage={industryAverage}
                          currency={currency_symbols[selectedCompany.currency]}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={ownGoalGraphNotes}
                          key1="ownGoalGraphNotes"
                          selectedDate={selectedDate}
                          notesRef={ownGoalNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </CardWrapper>
              <Box style={{ height: 25, width: "100%" }} />
              <CardWrapper border="1px solid #E7E7E7">
                <Grid container xs={12}>
                  {/* <Box style={{ height: 30, width: "100%" }} /> */}
                  <Box style={{ height: "100%", width: "100%" }}>
                    {periodData ? (
                      <Box ref={induestryAvgRef} sx={{ padding: "0 10px" }}>
                        <YouVsIndustryAvg
                          setIsGraphUpdate={setIsGraphUpdate}
                          isGraphUpdate={isGraphUpdate}
                          selectedYearDataVsThem={
                            periodData?.financialImpactVsThem
                          }
                          periodData={periodData}
                          industryAverage={industryAverage}
                          currency={currency_symbols[selectedCompany.currency]}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={industryAvgGraphNotes}
                          key1="industryAvgGraphNotes"
                          selectedDate={selectedDate}
                          notesRef={industryAvgNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Box style={{ height: 30, width: "100%" }} />
              <Box style={{ height: 30, width: "100%" }} /> */}
                </Grid>
              </CardWrapper>
              <Box style={{ height: 25, width: "100%" }} />
              <CardWrapper border="1px solid #E7E7E7">
                <Grid container xs={12}>
                  <Typography className="Comparative_Ratios">
                    Comparative Ratios
                  </Typography>
                  <Box
                    ref={comprtvRatioRef}
                    sx={{ padding: "0 20px", marginBottom: "10px" }}
                  >
                    <Box style={{ height: 25, width: "100%" }} />
                    <Grid container xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                        className="home_table_heading_row"
                      >
                        <Grid item xs={6}></Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          Company
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          Owner’s Goal
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          Industry Average
                        </Grid>
                      </Grid>
                      {Comparative_Ratios?.map((item, index) => (
                        <Grid
                          key={index}
                          container
                          xs={12}
                          className="home_table_data_row d-flex ai-center"
                        >
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                fontSize: 16,
                                color: "black",
                              }}
                            >
                              {item?.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            {item?.capmany}
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            {item?.owner_goal}
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            {item?.industry_average}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Grid item xs={12}>
                    <Box style={{ height: 20, width: "100%" }} />
                    <Grid container xs={12}>
                      <Accordian
                        noteData={comparativeRatioNotes}
                        key1="comparativeRatioNotes"
                        selectedDate={selectedDate}
                        notesRef={compRatioNotesRef}
                        setIsGraphUpdate={setIsGraphUpdate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardWrapper>
            </>
          )}
        </CardWrapper>
      </Layout>
    </React.Fragment>
  );
};
export default HiddenCash;
