const initialState = {
  periods: [],
  industryAverage: [],
  financialData: {},
  company: {},
  companyDetailsLoading: false,

  calculationRank: {
    currentRatio: "",
    quickRatio: "",
    debtEquityRatio: "",
    inventoryTurnover: "",
    arTurnover: "",
    grossMargin: "",
    netMargin: "",
    salesToAssets: "",
    ebidta: "",
    dcoh: "",
    dscr: "",
    ROA: "",
    ROI: "",
    apTurnover: "",
  },
};

const financialDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CALCULATION_RANK":
      return {
        ...state,
        calculationRank: action.payload.calculationRank,
      };

    case "RESET_CALCULATION_RANK":
      return {
        ...state,
        calculationRank: initialState.calculationRank,
      };
    default:
      return state;
  }
};

export default financialDataReducers;
