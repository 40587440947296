import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import Plan from "./plan";
import Pic1 from "../../images/pic1.png";
import Pic2 from "../../images/pic2.png";
import Pic3 from "../../images/pic3.png";
import { iniateStatesNotes } from "../../redux/actions/notes";
import { iniateStatesCompany } from "../../redux/actions/company";
import { iniateStatesCompanyDetails } from "../../redux/actions/companyDetails";
import { iniateStatesSnackBar } from "../../redux/actions/snackbar";
import { iniateStatesUser, signout } from "../../redux/actions/user";
import { useDispatch } from "react-redux";

export default function Select() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const monthPrice = "60.00";
  const yearPrice = "575.00";
  const monthlyPrice = "price_1MyNBgC3mzByfEvxXrGvtDsu";
  const yearlyPrice = "price_1NHrQjC3mzByfEvx3nK8Oroz";

  useEffect(() => {
    const tokenn = localStorage.getItem("token");
    if (!tokenn) {
      navigate("/");
    }
  }, [navigate]);

  const handleCloseLogout = () => {
    localStorage.clear();
    dispatch(iniateStatesNotes());
    dispatch(iniateStatesCompany());
    dispatch(iniateStatesCompanyDetails());
    dispatch(iniateStatesSnackBar());
    dispatch(iniateStatesUser());

    dispatch(signout(navigate));
  };

  const cards = [
    {
      img: Pic1,
      title: "BASIC",
      description: ` Unlock your business's full potential with Cash Flow Mike's tailored
            membership plans. Whether you're seeking foundational tools or
            comprehensive advisory training, our packages are designed to meet
            your unique needs. Explore our offerings to find the perfect fit for
            your financial goals.`,
    },
    {
      img: Pic2,
      title: "STANDARD",
      description: `Suited for those looking to deepen their expertise with
                comprehensive courses and technical skills training. It
                encompasses everything in the Basic Plan, plus the Clear Path To
                Cash Course, Pathfinder Course, and bi-monthly Technical Skills
                Training sessions.`,
    },
    {
      img: Pic3,
      title: "PROFESSIONAL",
      description: `Geared towards individuals aiming for comprehensive advisory
                proficiency, featuring advanced courses, personalized coaching,
                certification opportunities, and community engagement, in
                addition to all Standard Plan benefits.`,
    },
  ];

  return (
    <>
      <p onClick={handleCloseLogout} className="logout_sub_page">
        Logout
      </p>
      <div
        style={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#130E47",
        }}
        className="main_bg"
      >
        <div className="maindiv">
          <p className="text-1">Our Clear Path to Cash Membership Options:</p>
        </div>
        <div className="text-2div">
          <p className="text-2">
            Unlock your business's full potential with Cash Flow Mike's tailored
            membership plans. Whether you're seeking foundational tools or
            comprehensive advisory training, our packages are designed to meet
            your unique needs. Explore our offerings to find the perfect fit for
            your financial goals.
          </p>{" "}
        </div>
        <div className="maindiv2">
          {cards.map((cards) => (
            <div className="package">
              <img
                style={{
                  width: "300px",
                  height: "240px",
                }}
                src={cards.img}
                alt=""
              />
              <p style={{ height: "" }} className="subHead">
                {cards.title}
              </p>
              <div className="desc_div">
                <p style={{ height: "210px" }} className="subHead2">
                  {cards.description}
                </p>
                <button
                  onClick={() => {
                    window.open("https://cashflowmike.com/pricing/", "_blank");
                    handleCloseLogout();
                  }}
                  className="pack_btns"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <Grid container justifyContent='center'>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            padding: '45px',
            borderRadius: '20px',
            backgroundColor: 'white',
            border: '5px solid white',
          }}
          className='payment-box-bg'
        >
          <h2>Please select the Plan</h2>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Plan
                  price={monthPrice}
                  price_id={monthlyPrice}
                  text='MONTHLY'
                  duration='PER MONTH'
                />
              </Grid>
              <Grid item xs={6}>
                <Plan
                  price={yearPrice}
                  price_id={yearlyPrice}
                  text='ANNUAL'
                  duration='PER YEAR'
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid> */}
    </>
  );
}
